import React, { useEffect, useState, useContext, createContext } from 'react'
import {
  BrowserRouter,
  HashRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  WalletDataPersonaData,
  WalletDataState,
} from '@radixdlt/radix-dapp-toolkit'
import { handleWalletData } from '../actions/WalletActions'

import Home from './Home'
import Supply from './Supply'
import Borrow from './Borrow'
import Withdraw from './Withdraw'
import Repay from './Repay'
import Header from '../component/Header'
import Footer from '../component/Footer'
import Pool from './Pool'
import CDP from './CDP'
import ManageCdp from './ManageCdp'
import Transter from './Migrate'
import styled from 'styled-components'
import useWindowSize from '../utils/useWindowSize'
import { useRdt } from '../hooks/useRdt'

const App: React.FC = (props: any) => {
  const windowSize = useWindowSize()
  const [device, setDevice] = useState('desktop')
  const rdt = useRdt()
  const dispatch = useDispatch()
  rdt?.walletApi.walletData$.subscribe((walletData) => {
    dispatch(handleWalletData(walletData))
  })

  useEffect(() => {
    if (windowSize.width === undefined) return
    if (windowSize.width <= 768) {
      setDevice('mobile')
      return
    }
    if (windowSize.width <= 1024) {
      setDevice('tablet')
      return
    }
    if (windowSize.width <= 1200) {
      setDevice('laptop')
      return
    }
    setDevice('desktop')
  }, [windowSize])

  useEffect(() => {
    const walletDataState: WalletDataState | undefined =
      rdt?.walletApi.getWalletData()
    if (
      walletDataState &&
      walletDataState.accounts &&
      walletDataState.accounts.length > 0
    ) {
      dispatch(handleWalletData(walletDataState))
    }
  }, [])

  return (
    <div>
      <HashRouter>
        <Header device={device} />
        <MainLayout>
          <Background>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1882"
              height="1017"
              fill="none"
              viewBox="0 0 1882 1017"
            >
              <defs>
                <radialGradient
                  id="paint1_radial"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientTransform="matrix(0 379 -379 0 533 617.782)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#EAE2FF"></stop>
                  <stop offset="1" stopColor="#FFC8CE" stopOpacity="0"></stop>
                </radialGradient>
                <radialGradient
                  id="paint0_radial"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientTransform="matrix(0 379 -379 0 1387 619.782)"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#BFFFF0"></stop>
                  <stop offset="1" stopColor="#FEFFC7" stopOpacity="0"></stop>
                </radialGradient>
              </defs>
              <circle
                cx="387"
                cy="619.782"
                r="379"
                fill="url(#paint1_radial)"
              ></circle>
              <circle
                cx="1200"
                cy="200"
                r="487"
                fill="url(#paint0_radial)"
              ></circle>
            </svg>
          </Background>

          <Routes>
            <Route path="/" element={<Home device={device} />} />
            <Route path="/supply/:addr" element={<Supply device={device} />} />
            <Route
              path="/withdraw/:addr"
              element={<Withdraw device={device} />}
            />
            <Route path="/borrow" element={<Borrow device={device} />} />
            <Route path="/repay" element={<Repay device={device} />} />
            <Route path="/pool" element={<Pool device={device} />} />
            <Route path="/cdp" element={<CDP device={device} />} />
            <Route path="/migrate" element={<Transter device={device}/>}/>
            <Route
              path="/manage/:addr/:cdp"
              element={<ManageCdp device={device} />}
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </MainLayout>
        <Footer device={device} />
      </HashRouter>
    </div>
  )
}

const MainLayout = styled.div`
  box-sizing: border-box;
  margin: 0px auto 64px;
  min-width: 0px;
  width: 100%;
  max-width: 1200px;
  padding-left: 16px;
  padding-right: 16px;
  flex: 2 1 0%;
`

const Background = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  position: absolute;
  left: calc((100% - 1882px) / 2);
  top: -200px;
  right: 0px;
  z-index: -1;
  background-color: #fff;
  overflow: hidden;
`

export default App
