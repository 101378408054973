import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import SelectComponent from '../component/SelectComponent'
import AccountPicker from '../component/AccountPicker'
import CONFIG from '../env/index'
import { Popover, Alert, Spin, notification, Modal } from 'antd'
import {
  QuestionCircleOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons'
import { SelectListItem } from '../component/SelectComponent'
import NumericInput from '../component/NumericInput'
import {
  OverviewStatusType,
  PoolData,
  SubmitButtonStatusType,
} from '../component/Types'
import { useRdt } from '../hooks/useRdt'
import { useSelector } from 'react-redux'
import NavigateButtons from '../component/NavigateButtons'
import {
  queryAssetBalance,
  queryAssetStates,
  genPoolDataByAssetSate,
  numberToFactor,
  getLiquidationPrice,
  genMaxBorrowByDeposit,
  genLoanToValue,
  genTotalExposure,
  formatNumber,
  genManifestPriceParams,
  queryAssetMetaData
} from '../utils/ContractDataUtil'

interface Props {
  device: string
}

const Borrow: React.FC<Props> = (props) => {
  const [notificationApi, contextHolder] = notification.useNotification()
  const rdt = useRdt()
  const walletData = useSelector((state: any) => state.Wallet)
  const priceMap = useSelector((state: any) => state.Common.price_map)
  const common = useSelector((state:any) => state.Common)

  const [account, setAccount] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [depositTokenList, setDepositTokenList] = useState<SelectListItem[]>([])
  const [depositToken, setDepositToken] = useState<string>('')
  const [generateTokenList, setGenerateTokenList] = useState<SelectListItem[]>(
    []
  )
  const [generateToken, setGenerateToken] = useState<string>('')
  const [balance, setBalance] = useState<number>()
  const [maxBorrow, setMaxBorrow] = useState<number>(0)

  const [poolDataArray, setPoolDataArray] = useState<PoolData[]>([])

  const [depositValue, setDepositValue] = useState<string>('')
  const [borrowValue, setBorrowValue] = useState<string>('')
  const [isShowDepositAlert, setIsShowDepositAlert] = useState<boolean>(false)
  const [depositAlertMessage, setDepositAlertMessage] = useState<string>('')
  const [isShowBorrowAlert, setIsShowBorrowAlert] = useState<boolean>(false)
  const [borrowAlertMessage, setBorrowAlertMessage] = useState<string>('')
  const [submitButtonStatus, setSubmitButtonStatus] =
    useState<SubmitButtonStatusType>(SubmitButtonStatusType.DISABLE)
  const [overviewStatus, setOverviewStatus] = useState<OverviewStatusType>(
    OverviewStatusType.READY
  )

  const [isLiquidationPriceModalOpen, setIsLiquidationPriceModalOpen] =
    useState(false)
  const [
    isCollateralizationRatioModalOpen,
    setIsCollateralizationRatioModalOpen,
  ] = useState(false)
  const [isCollateralLockedModalOpen, setIsCollateralLockedModalOpen] =
    useState(false)
  const [isLoanToValueModalOpen, setIsLoanToValueModalOpen] = useState(false)

  useEffect(() => {
    initData()
  }, [walletData.connected, priceMap])

  const initData = async () => {
    if (walletData.connected) {
      if (poolDataArray.length > 0) {
        freshPrice()
      } else {
        setAccount(
          walletData.walletDataState.accounts[0].appearanceId.toString()
        )
        walletData.walletDataState.accounts.forEach((acct: any) => {
          if (
            acct.appearanceId.toString() ===
            walletData.walletDataState.accounts[0].appearanceId.toString()
          ) {
            setAddress(acct.address)
            fetchDepositTokens(acct.address)
          }
        })
        queryData(CONFIG.DEPOSIT_TOKENS[0].value)
      }
    }
  }

  const freshPrice = () => {
    if (walletData.connected && priceMap && poolDataArray.length > 0) {
      poolDataArray.forEach((item) => {
        item.price = priceMap.get(item.symbol)
        item.priceInXrd = priceMap.get(item.symbol + '_IN_XRD')
        item.tokenPrice = priceMap.get(item.symbol) / item.borrowIndex
        item.tokenPriceInXrd = priceMap.get(item.symbol + '_IN_XRD') / item.borrowIndex
      })
    }
  }

  const genAccountList = () => {
    var items: SelectListItem[] = []
    if (walletData.connected) {
      walletData.walletDataState.accounts.forEach((acct: any) => {
        let addr = acct.address
        items.push({
          label:
            acct.label +
            '(' +
            addr.substring(0, 4) +
            '...' +
            addr.substring(addr.length - 6, addr.length) +
            ')',
          key: acct.appearanceId.toString(),
          value: acct.appearanceId.toString(),
          sort: -1,
        })
      })
    }
    return items
  }

  const fetchTokenBalance = async (
    address: string,
    resourceAddress: string
  ) => {
    if (walletData.connected && address && resourceAddress) {
      const balanceResult = queryAssetBalance(rdt, address, resourceAddress)
      balanceResult.then((balance) => {
        setBalance(balance)
      })
      // let result = await queryAssetMetaData(rdt, resourceAddress);
      // console.log(result)
      // let result = await rdt?.gatewayApi.state.getEntityDetailsVaultAggregated('resource_tdx_2_1t42p2eyad03gly8txm28x4arzkx27wtjqtznz33smqt6ea2d7jlx2s');
      // console.log(result)
    }
  }

  const fetchDepositTokens = async (address: string) => {
    let depositTokenAddress = CONFIG.DEPOSIT_TOKENS[0].value
    setDepositToken(depositTokenAddress)
    setDepositTokenList(CONFIG.DEPOSIT_TOKENS)
    fetchTokenBalance(address, depositTokenAddress)
    let generateTokenList = CONFIG.DEPOSIT_GENERATE_DIC.find(
      (item: any) => item.address === depositTokenAddress
    )?.items
    if (generateTokenList !== undefined) {
      setGenerateToken(generateTokenList[0].value)
      setGenerateTokenList(generateTokenList)
    }
  }

  const changeAccount = (value: string) => {
    setAccount(value)
    walletData.walletDataState.accounts.forEach((acct: any) => {
      if (acct.appearanceId.toString() === value) {
        setAddress(acct.address)
        fetchDepositTokens(acct.address)
      }
    })
  }

  const changeDepositToken = (value: string) => {
    setGenerateTokenList([])
    setGenerateToken('')

    setDepositToken(value)
    fetchTokenBalance(address, value)
    let generateTokenList = CONFIG.DEPOSIT_GENERATE_DIC.find(
      (item: any) => item.address === value
    )?.items

    if (generateTokenList !== undefined) {
      setGenerateToken(generateTokenList[0].value)
      setGenerateTokenList(generateTokenList)
    }
    clearOverviewData()
    queryData(value)
  }

  const changeGenerateToken = (value: string) => {
    setGenerateToken(value)
    clearOverviewData()
  }

  const clearOverviewData = () => {
    setBorrowValue('')
    setDepositValue('')
    setMaxBorrow(0)
  }

  const queryData = async (address: string) => {
    if (walletData.connected === false) {
      return
    }
    setOverviewStatus(OverviewStatusType.LOADING)
    let depositAssetAddress = CONFIG.WITHDRAW_GENERATE_DIC.find(
      (item: any) => item.address === address
    )?.generate
    if (depositAssetAddress === undefined) {
      return
    }

    if (!priceMap) {
      return
    }
    queryAssetStates(rdt, handleAssetState);
  }

  const handleAssetState = async (assetStates:any)=>{
    var taskWatcher = assetStates.length;
    let dataArray: PoolData[] = []
    assetStates.forEach((assetState:any) => {
      const poolDataResult = genPoolDataByAssetSate(rdt, assetState, priceMap)
      poolDataResult.then((poolData) => {
        if (poolData !== undefined) {
          dataArray?.push(poolData)
          taskWatcher--
          dataArray.sort((a, b) => a.sort - b.sort)
        }
        if (taskWatcher === 0) {
          setPoolDataArray(dataArray)
          setOverviewStatus(OverviewStatusType.READY)
        }
      })
    })
  }

  const doBorrow = async (model: number) => {
    setSubmitButtonStatus(SubmitButtonStatusType.PROCESS)
    const borrow_model = model === 1 ? 'borrow_variable' : 'borrow_stable'
    // genManifestPriceParams(act:string, borrowTokenAddress:string, collateralTokenAddress:string, CONFIG:any, COMMON:any)
    let params = genManifestPriceParams(borrow_model, generateToken, depositToken, CONFIG, common);
    const price2Str = params.price2 === 'None' ? `Enum<0u8>()` : `Some("${params.price2}")`
    const quote2Str = params.quote2 === 'None' ? `Enum<0u8>()` : `Some(Address("${params.quote2}"))`
    const timestamp2Str = params.timestamp2 === 'None' ? `Enum<0u8>()` : `Some(${params.timestamp2}u64)`
    const signature2Str = params.signature2 === 'None' ? `Enum<0u8>()` : `Some("${params.signature2}")`
    

    let manifest = `
      CALL_METHOD
        Address("${address}")
        "withdraw"
        Address("${depositToken}")
        Decimal("${depositValue}");

      TAKE_FROM_WORKTOP
        Address("${depositToken}")
        Decimal("${depositValue}")
        Bucket("bucket1");

      CALL_METHOD
          Address("${CONFIG.LENDING_FACTORY_COMPONENT_ADDRESS}")
          "${borrow_model}"
          Bucket("bucket1")
          Address("${generateToken}")
          Decimal("${borrowValue}")
          "${params.price1}"
          Address("${params.quote1}")
          ${params.timestamp1}u64
          "${params.signature1}"
          ${price2Str} 
          ${quote2Str}
          ${timestamp2Str}
          ${signature2Str}
      ;
      CALL_METHOD
          Address("${address}")
          "deposit_batch"
          Expression("ENTIRE_WORKTOP")
      ;
    `
    console.log(manifest)
    
    const result = await rdt?.walletApi.sendTransaction({
      transactionManifest: manifest,
    })

    if (result?.isErr()) {
      setSubmitButtonStatus(SubmitButtonStatusType.READY)
      notificationApi.error({
        message: '',
        description: result.error.message,
        placement: 'bottomRight',
      })
      return
    }
    setSubmitButtonStatus(SubmitButtonStatusType.READY)
    fetchTokenBalance(address, depositToken)

    let ret: any = result
    notificationApi.success({
      message: '',
      description: (
        <div style={{ marginTop: '16px' }}>
          <a
            target="_blank"
            rel="noreferrer"
            href={
              CONFIG.DASHBOARD_URL +
              ret.value.transactionIntentHash +
              '/details'
            }
          >
            {ret.value.transactionIntentHash}
          </a>
        </div>
      ),
      placement: 'bottomRight',
    })
    changeDepositToken(depositToken)
    queryData(depositToken)
  }

  const onDepositMax = (balance:number|undefined) => {
    if(balance){
      const multiplier = Math.pow(10, 9);
      let v = Math.floor(balance * multiplier)/multiplier
      onDepositInputChange(v.toString())
    }
  }

  const onDepositInputChange = (value: string) => {
    if (!walletData.connected) {
      return
    }
    setDepositValue(value)
    const maxBorrow = genMaxBorrowByDeposit(
      poolDataArray,
      depositToken,
      Number(value),
      generateToken
    )
    setMaxBorrow(maxBorrow)
    updateSubmitButtonStatus(
      Number(value),
      Number(balance),
      overviewStatus,
      Number(borrowValue),
      maxBorrow
    )

    if (Number(value) > Number(balance)) {
      setDepositAlertMessage(
        'You cannot deposit more collateral than the amount in your wallet'
      )
      setIsShowDepositAlert(true)
    } else {
      setDepositAlertMessage('')
      setIsShowDepositAlert(false)
    }

    if (Number(borrowValue) > maxBorrow) {
      setBorrowAlertMessage(
        'You cannot borrow more than ' +
          formatNumber(maxBorrow) +
          poolDataArray.find((item) => item.address === generateToken)?.symbol
      )
      setIsShowBorrowAlert(true)
    } else {
      setBorrowAlertMessage('')
      setIsShowBorrowAlert(false)
    }
  }

  const onGenerateMax = (value:number|undefined) => {
    if(value){
      let v = Math.floor(value * 1000000000000000000) / 1000000000000000000
      onGenerateInputChange(v.toString())
    }
  }

  const onGenerateInputChange = (value: string) => {
    if (!walletData.connected) {
      return
    }
    setBorrowValue(value)
    const maxBorrow = genMaxBorrowByDeposit(
      poolDataArray,
      depositToken,
      Number(depositValue),
      generateToken
    )

    setMaxBorrow(Number(maxBorrow))
    updateSubmitButtonStatus(
      Number(depositValue),
      Number(balance),
      overviewStatus,
      Number(borrowValue),
      maxBorrow
    )

    if (Number(value) > maxBorrow) {
      setBorrowAlertMessage(
        'You cannot borrow more than ' +
          maxBorrow.toFixed(4) +
          poolDataArray.find((item) => item.address === generateToken)?.symbol
      )
      setIsShowBorrowAlert(true)
    } else {
      setBorrowAlertMessage('')
      setIsShowBorrowAlert(false)
    }
  }

  const updateSubmitButtonStatus = (
    depositAmount: number,
    balance: number,
    overviewStatus: OverviewStatusType,
    borrowAmount: number,
    maxBorrowAmount: number
  ) => {
    if (
      depositAmount === 0 ||
      depositAmount > balance ||
      borrowAmount > maxBorrowAmount
    ) {
      setSubmitButtonStatus(SubmitButtonStatusType.DISABLE)
    } else {
      if (overviewStatus !== OverviewStatusType.READY) {
        setSubmitButtonStatus(SubmitButtonStatusType.DISABLE)
      } else {
        setSubmitButtonStatus(SubmitButtonStatusType.READY)
      }
    }
  }

  const genLiquidationPriceAfter = () => {
    if (
      Number(depositValue) > 0 &&
      overviewStatus === OverviewStatusType.READY
    ) {
      const price = getLiquidationPrice(
        poolDataArray,
        null,
        depositToken,
        Number(depositValue),
        generateToken,
        Number(borrowValue)
      ).toFixed(4)
      return (
        <OverviewAfterDataDiv>
          <OverviewAfterDataP>{price} After</OverviewAfterDataP>
        </OverviewAfterDataDiv>
      )
    } else {
      return <></>
    }
  }
  const genLoanToValueAfter = () => {
    if (
      Number(depositValue) > 0 &&
      overviewStatus === OverviewStatusType.READY
    ) {
      const ltv = genLoanToValue(
        poolDataArray,
        null,
        depositToken,
        Number(depositValue),
        generateToken,
        Number(borrowValue)
      )
      return (
        <OverviewAfterDataDiv>
          <OverviewAfterDataP>
            {numberToFactor(ltv, 4)} After
          </OverviewAfterDataP>
        </OverviewAfterDataDiv>
      )
    } else {
      return <></>
    }
  }

  const genTotalExposureAfter = () => {
    if (
      Number(depositValue) > 0 &&
      overviewStatus === OverviewStatusType.READY
    ) {
      const totalExposure = genTotalExposure(
        poolDataArray,
        null,
        depositToken,
        Number(depositValue)
      )
      return (
        <OverviewAfterDataDiv>
          <OverviewAfterDataP>{totalExposure} After</OverviewAfterDataP>
        </OverviewAfterDataDiv>
      )
    } else {
      return <></>
    }
  }
  const genPositionDebtAfter = () => {
    if (
      Number(depositValue) > 0 &&
      overviewStatus === OverviewStatusType.READY
    ) {
      const positionDebet = genTotalExposure(
        poolDataArray,
        null,
        generateToken,
        Number(borrowValue)
      )
      return (
        <OverviewAfterDataDiv>
          <OverviewAfterDataP>{positionDebet} After</OverviewAfterDataP>
        </OverviewAfterDataDiv>
      )
    } else {
      return <></>
    }
  }

  return (
    <Layout>
      {contextHolder}
      <LayoutContainer>
        <NavigateButtons />

        <DataZone device={props.device}>
          <div>
            <OverviewZone>
              <BoxTitle>
                <BoxTitleContainer>
                  <BoxTitleContent>Overview</BoxTitleContent>
                </BoxTitleContainer>
              </BoxTitle>

              <BoxLayout>
                <OverviewData device={props.device}>
                  <OverviewDataItem
                    onClick={() => {
                      setIsLiquidationPriceModalOpen(true)
                    }}
                  >
                    <OverviewDataItemT>
                      Liquidation Price{' '}
                      <Popover placement="topLeft">
                        <QuestionCircleOutlined />
                      </Popover>
                    </OverviewDataItemT>
                    {overviewStatus === OverviewStatusType.LOADING ? (
                      <OverviewDataItemIcon>
                        <Loading3QuartersOutlined spin />
                      </OverviewDataItemIcon>
                    ) : (
                      <OverviewDataItemC>
                        0{' '}
                        {
                          poolDataArray.find(
                            (item) => item.token === depositToken
                          )?.tokenSymbol
                        }
                      </OverviewDataItemC>
                    )}
                    {genLiquidationPriceAfter()}
                  </OverviewDataItem>
                  <OverviewDataItem
                    onClick={() => {
                      setIsCollateralizationRatioModalOpen(true)
                    }}
                  >
                    <OverviewDataItemT>
                      Collateralization Ratio{' '}
                      <Popover placement="topLeft" content="">
                        <QuestionCircleOutlined />
                      </Popover>
                    </OverviewDataItemT>
                    {overviewStatus === OverviewStatusType.LOADING ? (
                      <OverviewDataItemIcon>
                        <Loading3QuartersOutlined spin />
                      </OverviewDataItemIcon>
                    ) : (
                      <OverviewDataItemC>0.00%</OverviewDataItemC>
                    )}
                    {genLoanToValueAfter()}
                  </OverviewDataItem>
                  <OverviewDataItem
                    onClick={() => {
                      setIsCollateralLockedModalOpen(true)
                    }}
                  >
                    <OverviewDataItemT>
                      Collateral Locked{' '}
                      <Popover placement="topLeft" content="">
                        <QuestionCircleOutlined />
                      </Popover>
                    </OverviewDataItemT>
                    {overviewStatus === OverviewStatusType.LOADING ? (
                      <OverviewDataItemIcon>
                        <Loading3QuartersOutlined spin />
                      </OverviewDataItemIcon>
                    ) : (
                      <OverviewDataItemC>
                        0{' '}
                        {
                          poolDataArray.find(
                            (item) => item.token === depositToken
                          )?.tokenSymbol
                        }
                      </OverviewDataItemC>
                    )}
                    {genTotalExposureAfter()}
                  </OverviewDataItem>
                  <OverviewDataItem
                    onClick={() => {
                      setIsLoanToValueModalOpen(true)
                    }}
                  >
                    <OverviewDataItemT>
                      Loan to Value{' '}
                      <Popover placement="topLeft" content="">
                        <QuestionCircleOutlined />
                      </Popover>
                    </OverviewDataItemT>
                    {overviewStatus === OverviewStatusType.LOADING ? (
                      <OverviewDataItemIcon>
                        <Loading3QuartersOutlined spin />
                      </OverviewDataItemIcon>
                    ) : (
                      <OverviewDataItemC>
                        {numberToFactor(
                          Number(
                            poolDataArray.find(
                              (item) => item.token === depositToken
                            )?.ltv
                          )
                        )}
                      </OverviewDataItemC>
                    )}
                  </OverviewDataItem>
                </OverviewData>
              </BoxLayout>
              <BottomBoxLayout>
                <OverviewData device={props.device}>
                  <OverviewDataItemPure>
                    <OverviewDataItemT>Total exposure</OverviewDataItemT>
                    {overviewStatus === OverviewStatusType.LOADING ? (
                      <OverviewDataItemIcon>
                        <Loading3QuartersOutlined spin />
                      </OverviewDataItemIcon>
                    ) : (
                      <OverviewBottomDataItemC>
                        0{' '}
                        {
                          poolDataArray.find(
                            (item) => item.token === depositToken
                          )?.tokenSymbol
                        }
                      </OverviewBottomDataItemC>
                    )}
                    {genTotalExposureAfter()}
                  </OverviewDataItemPure>
                  <OverviewDataItemPure>
                    <OverviewDataItemT>Position Debt</OverviewDataItemT>
                    {overviewStatus === OverviewStatusType.LOADING ? (
                      <OverviewDataItemIcon>
                        <Loading3QuartersOutlined spin />
                      </OverviewDataItemIcon>
                    ) : (
                      <OverviewBottomDataItemC>
                        0{' '}
                        {
                          poolDataArray.find(
                            (item) => item.address === generateToken
                          )?.symbol
                        }
                      </OverviewBottomDataItemC>
                    )}
                    {genPositionDebtAfter()}
                  </OverviewDataItemPure>
                </OverviewData>
              </BottomBoxLayout>
            </OverviewZone>
          </div>

          <div>
            <OptZone>
              <BoxTitle>
                <BoxTitleContainer>
                  <BoxTitleContent>
                    Open a Collateral Debt Position
                  </BoxTitleContent>
                </BoxTitleContainer>
              </BoxTitle>

              <BoxLayout>
                {/* <div> */}
                <FlexZone>
                  <FormLineTitle>Account</FormLineTitle>
                  <div style={{ width: '100%', paddingLeft: '16px' }}>
                    <AccountPicker
                      disable={walletData.connected ? false : true}
                      device={props.device}
                      onSelect={changeAccount}
                      defaultValue={account}
                      items={genAccountList()}
                    />
                  </div>
                </FlexZone>
                {/* </div> */}
                <Space />

                {/* <FlexZone> */}
                <FlexZone>
                  <FormLineTitle style={{ marginBottom: '16px' }}>
                    Deposit
                  </FormLineTitle>
                  <FormLineRight>
                    <div>
                      <NumericInput
                        style={{ width: '', height: 38 }}
                        onChange={onDepositInputChange}
                        value={String(depositValue)}
                        disabled={walletData.connected ? false : true}
                      />
                      <div>
                        <span
                          style={{
                            fontSize: '14px',
                            color: '#003057',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            onDepositMax(balance)
                          }}
                        >
                          {formatNumber(balance)}
                        </span>
                        <span style={{ fontSize: '12px', color: '#8A8FA4' }}>
                          (Available balance)
                        </span>
                      </div>
                    </div>
                    <div>
                      <SelectComponent
                        height="32px"
                        device={props.device}
                        onSelect={changeDepositToken}
                        defaultValue={depositToken}
                        items={depositTokenList}
                      />
                    </div>
                  </FormLineRight>
                </FlexZone>
                {/* </FlexZone> */}

                {isShowDepositAlert ? (
                  <Alert
                    style={{ marginTop: '8px', marginBottom: '8px' }}
                    message={depositAlertMessage}
                    type="error"
                  />
                ) : (
                  <></>
                )}

                <Space />
                {/* <FlexZone> */}
                <FlexZone>
                  <FormLineTitle style={{ marginBottom: '16px' }}>
                    Borrow
                  </FormLineTitle>
                  <FormLineRight>
                    <div>
                      <NumericInput
                        style={{ width: '', height: 38 }}
                        onChange={onGenerateInputChange}
                        value={String(borrowValue)}
                        disabled={false}
                      />
                      <div>
                        <span
                          style={{
                            fontSize: '14px',
                            color: '#003057',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            onGenerateMax(maxBorrow)
                          }}
                        >
                          {formatNumber(maxBorrow)}
                        </span>
                        <span style={{ fontSize: '12px', color: '#8A8FA4' }}>
                          (Available Max)
                        </span>
                      </div>
                    </div>
                    <div>
                      <SelectComponent
                        height="32px"
                        device={props.device}
                        onSelect={changeGenerateToken}
                        defaultValue={generateToken}
                        value={generateToken}
                        items={generateTokenList}
                      />
                    </div>
                  </FormLineRight>
                </FlexZone>
                {/* </FlexZone> */}
                {isShowBorrowAlert ? (
                  <Alert
                    style={{ marginTop: '8px', marginBottom: '8px' }}
                    message={borrowAlertMessage}
                    type="error"
                  />
                ) : (
                  <></>
                )}
              </BoxLayout>
            </OptZone>

            <SubmitWrapper>
              <SubmitButton
                status={submitButtonStatus}
                disabled={
                  submitButtonStatus === SubmitButtonStatusType.DISABLE ||
                  submitButtonStatus === SubmitButtonStatusType.PROCESS
                    ? true
                    : false
                }
                onClick={() => doBorrow(1)}
              >
                {submitButtonStatus === SubmitButtonStatusType.READY ||
                submitButtonStatus === SubmitButtonStatusType.DISABLE ? (
                  'Borrow'
                ) : (
                  <Spin
                    indicator={
                      <Loading3QuartersOutlined
                        style={{ fontSize: '37px', color: '#FFF' }}
                        spin
                      />
                    }
                  ></Spin>
                )}
              </SubmitButton>
              {/* <SubmitButton status={submitButtonStatus} disabled={submitButtonStatus === SubmitButtonStatusType.DISABLE || submitButtonStatus === SubmitButtonStatusType.PROCESS ? true: false} onClick={()=>doBorrow(2)}>
              {submitButtonStatus === SubmitButtonStatusType.READY || submitButtonStatus === SubmitButtonStatusType.DISABLE ? 'Borrow Stable':
              <Spin indicator={<Loading3QuartersOutlined style={{ fontSize: '37px', color:'#FFF'}} spin/>}></Spin>
              }
            </SubmitButton> */}
            </SubmitWrapper>
          </div>
        </DataZone>

        <Modal
          title="Liquidation Price"
          maskClosable={true}
          open={isLiquidationPriceModalOpen}
          onCancel={() => {
            setIsLiquidationPriceModalOpen(false)
          }}
          footer={null}
        >
          <p>
            The Liquidation Price is the price at which a Vault becomes
            vulnerable to liquidation
          </p>
        </Modal>

        <Modal
          title="Collateralization Ratio"
          maskClosable={true}
          open={isCollateralizationRatioModalOpen}
          onCancel={() => {
            setIsCollateralizationRatioModalOpen(false)
          }}
          footer={null}
        >
          <p>
            Your collateralization ratio is calculated by: value of your
            collateral /{' '}
            {
              poolDataArray.find((item) => item.address === generateToken)
                ?.symbol
            }{' '}
            debt.
          </p>
          <p>
            The price used to calculate the value of your collateral is the
            price obtained from the Maker oracles. Your collateralization ratio
            needs to be above the minimum required at all times to not get
            liquidated.
          </p>
        </Modal>

        <Modal
          title="Collateral Locked"
          maskClosable={true}
          open={isCollateralLockedModalOpen}
          onCancel={() => {
            setIsCollateralLockedModalOpen(false)
          }}
          footer={null}
        >
          <p>The total amount of collateral locked in your vault.</p>
        </Modal>

        <Modal
          title="Loan To Value"
          maskClosable={true}
          open={isLoanToValueModalOpen}
          onCancel={() => {
            setIsLoanToValueModalOpen(false)
          }}
          footer={null}
        >
          <p>
            This is a financial term to express the ratio of a loan to the value
            of an asset purchased.{' '}
          </p>
          <p>
            If this value is equal to 0, it means that the collateral does not
            support the loan
          </p>
        </Modal>
      </LayoutContainer>
    </Layout>
  )
}

// Layout
const Layout = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
  height: 100%;
  display: flex;
`
const LayoutContainer = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex: 1 1 0%;
  position: relative;
`

// Data zone
const DataZone = styled.div`
  gap: ${(props: { device: String }) =>
    props.device === 'mobile' ? '24px' : '48px'};
  grid-template-columns: ${(props: { device: String }) =>
    props.device === 'mobile' ? '2f4' : '2fr minmax(465px, 1fr)'};
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: grid;
  align-items: flex-start;
`
const OverviewZone = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 0px;
  border: 1px solid rgb(240, 240, 240);
  margin-top: 20px;
`
const BoxTitle = styled.div`
  box-sizing: border-box;
  margin: 0px 16px;
  min-width: 0px;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgb(240, 240, 240);
  display: flex;
`
const BoxTitleContainer = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  -webkit-box-align: center;
  align-items: center;
  height: 40px;
  display: flex;
`
const BoxTitleContent = styled.p`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-family: Inter, 'Helvetica Neue', sans-serif;
  font-weight: 600;
  line-height: 24px;
  font-size: 16px;
  color: #25273d;
`

const BoxLayout = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  padding: 24px 16px;
`

const BottomBoxLayout = styled.div`
  border-top: 1px solid rgb(240, 240, 240);
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  padding: 24px 16px;
`

const OverviewData = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: grid;
  gap: 16px;
  grid-template-columns: ${(props: { device: String }) =>
    props.device === 'mobile' ? '1fr' : '1fr 1fr'};
`

const OverviewDataItem = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  border-radius: 8px;
  background-color: #ffffff;
  transition: background-color 200ms ease 0s;
  overflow-wrap: break-word;
  display: flex;
  cursor: pointer;
  :hover {
    background-color: #f3f7f9;
  }
`

const OverviewDataItemPure = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  border-radius: 8px;
  background-color: #ffffff;
  transition: background-color 200ms ease 0s;
  overflow-wrap: break-word;
  display: flex;
`

const OverviewDataItemT = styled.h3`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-family: Inter, 'Helvetica Neue', sans-serif;
  font-weight: 600;
  line-height: 20px;
  font-size: 12px;
  color: #787a9b;
`

const OverviewDataItemC = styled.p`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-family: __FTPolarMedium_6973ac, __FTPolarMedium_Fallback_6973ac,
    'Helvetica Neue', sans-serif;
  font-weight: 500;
  font-size: 32px;
  color: #25273d;
  max-width: 100%;
  line-height: 1.35;
`

const OverviewBottomDataItemC = styled.p`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-family: __FTPolarMedium_6973ac, __FTPolarMedium_Fallback_6973ac,
    'Helvetica Neue', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #25273d;
  max-width: 100%;
  line-height: 1.35;
`

const OverviewDataItemIcon = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-family: __FTPolarMedium_6973ac, __FTPolarMedium_Fallback_6973ac,
    'Helvetica Neue', sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: #8a8fa4;
  max-width: 100%;
  line-height: 1.35;
`
const OverviewAfterDataDiv = styled.div`
  padding-top: 8px;
`
const OverviewAfterDataP = styled.p`
  color: #1aab9b;
  background-color: #e7fcfa;
  border-radius: 12px;
  padding: 4px 16px;
  font-size: 12px;
`

const OptZone = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  border-radius: 20px;
  background-color: #ffffff;
  position: relative;
  padding: 0px;
  border: 1px solid rgb(240, 240, 240);
  margin-top: 20px;
`

const FlexZone = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
const FormLineTitle = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 80px;
  // padding-right: 16px;
  // margin-right: 16px;
  text-align: right;
`
const FormLineRight = styled.div`
  display: grid;
  width: 100%;
  padding-left: 16px;
  grid-template-columns: 2fr 1fr;
  gap: 8px;
`
// submit
const SubmitWrapper = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  padding: 24px;
  // border-top: 1px solid rgb(240, 240, 240);
`
// background-color: var(--theme-ui-colors-neutral60,#BEC9D0);
const SubmitButton = styled.button`
  // pointer-events: none;
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  appearance: none;
  text-align: center;
  text-decoration: none;
  padding: 8px 16px;
  background-color: ${(props: { status: SubmitButtonStatusType }) =>
    props.status === SubmitButtonStatusType.DISABLE ||
    props.status === SubmitButtonStatusType.PROCESS
      ? '#787A9B'
      : '#25273D'};
  border: 0px;
  font-family: Inter, 'Helvetica Neue', sans-serif;
  line-height: 2.1em;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
  font-weight: 600;
  border-radius: 32px;
  transition: background 200ms ease 0s;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  :hover {
    background-color: #787a9b;
  }
`
const Space = styled.div`
  height: 16px;
`

export default Borrow
