import React from 'react';
import styled from 'styled-components';

interface Props{
  device: string
}


const Footer:React.FC<Props> = (props)=>{
  return(
    <Layout>
      <Content device={props.device}>
        <div>
          <div style={{fontWeight:'600', fontSize:'.875rem', color:'#1b2b8a'}}>Join The Community</div>
          <div style={{marginTop:'1rem', display:'flex', gap:'1rem'}}>
            <a target="_blank" rel="noreferrer" href="https://twitter.com/DeXianProtocol"><img src="/social/new_twitter.svg" loading="lazy" alt="Twitter" width={24} height={24}></img></a>
            <a target="_blank" rel="noreferrer"  href="https://t.me/dexianio"><img src="/social/telegram.svg" loading="lazy" alt="telegram logo" width={24} height={24}></img></a>
            <a target="_blank" rel="noreferrer"  href="https://github.com/dexianprotocol"><img src="/social/github.svg" loading="lazy" alt="" width={24} height={24}></img></a>
            <a target="_blank" rel="noreferrer"  href="https://docs.dexian.io"><img src="/social/docs.svg" loading="lazy" alt="" width={24} height={24}></img></a>
            <a target="_blank" rel="noreferrer"  href='https://docs.google.com/forms/d/e/1FAIpQLSevm4gma6hQMqAIjEDesPbjUvna4V7RPBtN79yj6nBOv3Uz6A/viewform'><img src="/social/envelop.svg" loading="lazy" alt="feedback" width={24} height={24}></img></a> 
          </div>
        </div>
        {/* <div>
          <div style={{fontWeight:'600', fontSize:'.875rem', color:'#1b2b8a'
        }}>Issue Feedback</div>
          <div style={{marginTop:'1rem', display:'flex', gap:'1rem'}}>
            <a target="_blank" rel="noreferrer"  href='https://docs.google.com/forms/d/e/1FAIpQLSevm4gma6hQMqAIjEDesPbjUvna4V7RPBtN79yj6nBOv3Uz6A/viewform'><img style={{width:'24px', height:'24px'}} src="https://gumball-club.radixdlt.com/_next/static/media/email.c9257a84.svg" loading="lazy" alt="feedback"></img></a> 
          </div>
        </div> */}
        <div>
            <a target="_blank" rel="noreferrer"  href="https://www.radixdlt.com/full-stack">
              <img alt="developer console link" loading="lazy" width="119.483" height="48.081" decoding="async" data-nimg="1" src="https://gumball-club.radixdlt.com/_next/static/media/runs-on-radix.ac01884b.svg" style={{color: "transparent"}}></img>
            </a>
        </div>
      </Content>
      <Border></Border>
      <Copyright>
        <div>Dexian 2023</div>
        <div style={{display:'flex',gap:'1.5rem'}}>
          <a target="_blank" style={{fontSize:'.75rem', color:'#6b7280', textDecoration:'none', cursor:'pointer'}} href='https://docs.dexian.io/docs/privacy'>Privacy Notice</a>
          <div style={{width:'1px', height: '1.5rem', backgroundColor:'#e5e7eb', alignSelf:'center'}}></div>
          <a target="_blank" style={{fontSize:'.75rem', color:'#6b7280', textDecoration:'none', cursor:'pointer'}} href='https://docs.dexian.io/docs/terms_conditions'>Terms and Condition</a>
          <div style={{width:'1px', height: '1.5rem', backgroundColor:'#e5e7eb', alignSelf:'center'}}></div>
          <a target="_blank" style={{fontSize:'.75rem', color:'#6b7280', textDecoration:'none', cursor:'pointer'}} href='https://docs.dexian.io/docs/disclaimer'>Disclaimer</a>
        </div>
      </Copyright>
    </Layout>
  );
}

const Layout = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 2rem;
  padding-left: 16px;
  padding-right: 16px;
`
const Content = styled.div`
  display: grid;
  grid-template-columns: ${(props: {device: String}) => props.device === 'mobile' ? '1fr' : '1fr 119px'};
`
const Border = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e5e7eb;
  margin: 3rem 0;
`
const Copyright = styled.div`
  display: flex;
  font-size: .75rem;
  color: #6b7280;
  line-height: 1.875rem;
  justify-content: space-between;
`

export default Footer;