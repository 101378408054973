// var env = "stokenet"
var env = "mainnet"
var config:any
var config_file = env + '.json'
try {
  config = require('./config-env/' + config_file)
  config.POOL_TOKENS = [
    {
      label: 'XRD',
      key: 'XRD',
      value: config.XRD_ADDRESS,
      sort: 1,
    },
    {
      label: 'USDT',
      key: 'USDT',
      value: config.USDT_ADDRESS,
      sort: 2,
    },
    {
      label: 'USDC',
      key: 'USDC',
      value: config.USDC_ADDRESS,
      sort: 3,
    },
  ]

  config.DEFAULT_POOL_TOKEN_ADDRESS = config.XRD_ADDRESS

  config.DEPOSIT_TOKENS = [
    { label: 'dxXRD', key: 'dxXRD', value: config.DX_XRD_ADDRESS, sort: 1 },
    { label: 'dxUSDT', key: 'dxUSDT', value: config.DX_USDT_ADDRESS, sort: 2 },
    { label: 'dxUSDC', key: 'dxUSDC', value: config.DX_USDC_ADDRESS, sort: 3 },
  ]
  config.BORROW_RESOURCE_LIST = [
    { label: 'XRD', key: 'XRD', value: config.XRD_ADDRESS, sort: 1 },
    { label: 'USDT', key: 'USDT', value: config.USDT_ADDRESS, sort: 2 },
    { label: 'USDC', key: 'USDC', value: config.USDC_ADDRESS, sort: 3 },
  ]
  config.DEPOSIT_GENERATE_DIC = [
    {
      address: config.DX_XRD_ADDRESS, //dxXRD
      items: [
        { label: 'USDT', key: 'USDT', value: config.USDT_ADDRESS, sort: 1 },
        { label: 'USDC', key: 'USDC', value: config.USDC_ADDRESS, sort: 2 },
      ],
    },
    {
      address: config.DX_USDT_ADDRESS, //dxUSDT
      items: [
        { label: 'XRD', key: 'XRD', value: config.XRD_ADDRESS, sort: 1 },
        { label: 'USDC', key: 'USDC', value: config.USDC_ADDRESS, sort: 2 },
      ],
    },
    {
      address: config.DX_USDC_ADDRESS, //dxUSDC
      items: [
        { label: 'XRD', key: 'XRD', value: config.XRD_ADDRESS, sort: 1 },
        { label: 'USDT', key: 'USDT', value: config.USDT_ADDRESS, sort: 1 },
      ],
    },
  ]
  config.WITHDRAW_GENERATE_DIC = [
    {
      address: config.DX_XRD_ADDRESS,
      generate: config.XRD_ADDRESS,
    },
    {
      address: config.DX_USDT_ADDRESS,
      generate: config.USDT_ADDRESS,
    },
    {
      address: config.DX_USDC_ADDRESS,
      generate: config.USDC_ADDRESS,
    },
  ]
  // 下面是为了处理旧数据迁移
  config.OLD_ENV.DEPOSIT_TOKENS = [
    { label: 'dxXRD', key: 'dxXRD', value: config.OLD_ENV.DX_XRD_ADDRESS, sort: 1 },
    { label: 'dxUSDT', key: 'dxUSDT', value: config.OLD_ENV.DX_USDT_ADDRESS, sort: 2 },
    { label: 'dxUSDC', key: 'dxUSDC', value: config.OLD_ENV.DX_USDC_ADDRESS, sort: 3 },
  ]

  config.OLD_ENV.BORROW_RESOURCE_LIST = [
    { label: 'XRD', key: 'XRD', value: config.OLD_ENV.XRD_ADDRESS, sort: 1 },
    { label: 'USDT', key: 'USDT', value: config.OLD_ENV.USDT_ADDRESS, sort: 2 },
    { label: 'USDC', key: 'USDC', value: config.OLD_ENV.USDC_ADDRESS, sort: 3 },
  ]

  config.OLD_ENV.DEPOSIT_GENERATE_DIC = [
    {
      address: config.DX_XRD_ADDRESS, //dxXRD
      items: [
        { label: 'USDT', key: 'USDT', value: config.OLD_ENV.USDT_ADDRESS, sort: 1 },
        { label: 'USDC', key: 'USDC', value: config.OLD_ENV.USDC_ADDRESS, sort: 2 },
      ],
    },
    {
      address: config.DX_USDT_ADDRESS, //dxUSDT
      items: [
        { label: 'XRD', key: 'XRD', value: config.OLD_ENV.XRD_ADDRESS, sort: 1 },
        { label: 'USDC', key: 'USDC', value: config.OLD_ENV.USDC_ADDRESS, sort: 2 },
      ],
    },
    {
      address: config.DX_USDC_ADDRESS, //dxUSDC
      items: [
        { label: 'XRD', key: 'XRD', value: config.OLD_ENV.XRD_ADDRESS, sort: 1 },
        { label: 'USDT', key: 'USDT', value: config.OLD_ENV.USDT_ADDRESS, sort: 1 },
      ],
    },
  ]

  config.OLD_ENV.WITHDRAW_GENERATE_DIC = [
    {
      address: config.OLD_ENV.DX_XRD_ADDRESS,
      generate: config.OLD_ENV.XRD_ADDRESS,
    },
    {
      address: config.OLD_ENV.DX_USDT_ADDRESS,
      generate: config.OLD_ENV.USDT_ADDRESS,
    },
    {
      address: config.OLD_ENV.DX_USDC_ADDRESS,
      generate: config.OLD_ENV.USDC_ADDRESS,
    },
  ]

  config.OLD_ENV.POOL_TOKENS = [
    {
      label: 'XRD',
      key: 'XRD',
      value: config.OLD_ENV.XRD_ADDRESS,
      sort: 1,
    },
    {
      label: 'USDT',
      key: 'USDT',
      value: config.OLD_ENV.USDT_ADDRESS,
      sort: 2,
    },
    {
      label: 'USDC',
      key: 'USDC',
      value: config.OLD_ENV.USDC_ADDRESS,
      sort: 3,
    },
  ]
  config.OLD_ENV.DEPOSIT_TOKENS = [
    { label: 'dxXRD', key: 'dxXRD', value: config.OLD_ENV.DX_XRD_ADDRESS, sort: 1 },
    { label: 'dxUSDT', key: 'dxUSDT', value: config.OLD_ENV.DX_USDT_ADDRESS, sort: 2 },
    { label: 'dxUSDC', key: 'dxUSDC', value: config.OLD_ENV.DX_USDC_ADDRESS, sort: 3 },
  ]

  config.OLD_ENV.MIGRATE_POOL_ADDRESS = [
    { from: config.OLD_ENV.DX_XRD_ADDRESS, to:config.OLD_ENV.XRD_ADDRESS},
    { from: config.OLD_ENV.DX_USDT_ADDRESS, to:config.OLD_ENV.USDT_ADDRESS},
    { from: config.OLD_ENV.DX_USDC_ADDRESS, to:config.OLD_ENV.USDC_ADDRESS},
  ]

} catch (err:any) {
  if (err.code && err.code === 'MODULE_NOT_FOUND') {
    console.error(
      'No config file matching ENV=' + env + '. Requires "' + env + '.json"'
    )
    process.exit(1)
  } else {
    throw err
  }
}

export default config
