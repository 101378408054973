import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useParams, Navigate } from 'react-router-dom'

import { Popover, Alert, Spin, notification, Modal } from 'antd'
import {
  QuestionCircleOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons'

import CONFIG from '../env/index'
import {
  queryAssetStates,
  genPoolDataByAssetSate,
  numberToPrice,
  numberToFactor,
  queryAssetBalance,
  getUtilizationFactorByPoolData,
  queryAssetMetaData,
  formatNumber,
} from '../utils/ContractDataUtil'

import SelectComponent from '../component/SelectComponent'
import AccountPicker from '../component/AccountPicker'
import { SelectListItem } from '../component/SelectComponent'
import NumericInput from '../component/NumericInput'
import NavigateButtons from '../component/NavigateButtons'
import {
  OverviewStatusType,
  SubmitButtonStatusType,
  PoolData,
} from '../component/Types'

import { useRdt } from '../hooks/useRdt'

interface Props {
  device: string
}

const Supply: React.FC<Props> = (props) => {
  const [notificationApi, contextHolder] = notification.useNotification()
  const walletData = useSelector((store: any) => store.Wallet)
  const priceMap = useSelector((store: any) => store.Common.price_map)
  const params = useParams()
  const rdt = useRdt()

  const [account, setAccount] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [token, setToken] = useState<string>('')
  const [balance, setBalance] = useState<number>()
  const [poolData, setPoolData] = useState<PoolData>()
  const [assetIconUrl, setAssetIconUrl] = useState<string>('')
  const [assetSymbol, setAssetSymbol] = useState<string>('')

  const [inputValue, setInputValue] = useState<string>('')
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<string>('')
  const [submitButtonStatus, setSubmitButtonStatus] =
    useState<SubmitButtonStatusType>(SubmitButtonStatusType.DISABLE)
  const [overviewStatus, setOverviewStatus] = useState<OverviewStatusType>(
    OverviewStatusType.LOADING
  )

  const [isSupplyRateModalOpen, setIsSupplyRateModalOpen] = useState(false)
  const [isBorrowRateModalOpen, setIsBorrowRateModalOpen] = useState(false)
  const [isTotalSupplyModalOpen, setIsTotalSupplyModalOpen] = useState(false)
  const [isAssetPriceModalOpen, setIsAssetPriceModalOpen] = useState(false)
  const [isUtilizationFactorModalOpen, setIsUtilizationFactorModalOpen] =
    useState(false)

  useEffect(() => {
    initData()
  }, [walletData.connected, priceMap])

  if (params.addr === undefined) {
    return <Navigate to="/" />
  }

  const initData = async () => {
    if (walletData.connected) {
      if (poolData) {
        freshPrice()
      } else {
        setAccount(
          walletData.walletDataState.accounts[0].appearanceId.toString()
        )
        setToken(params.addr === undefined ? '' : params.addr)
        fetchAssetMetaData(params.addr === undefined ? '' : params.addr)
        walletData.walletDataState.accounts.forEach((acct: any) => {
          if (
            acct.appearanceId.toString() ===
            walletData.walletDataState.accounts[0].appearanceId.toString()
          ) {
            setAddress(acct.address)
            fetchTokenBalance(acct.address, params.addr)
          }
        })
        queryData(params.addr)
      }
    }
  }

  const genAccountList = () => {
    var items: SelectListItem[] = []
    if (walletData.connected) {
      walletData.walletDataState.accounts.forEach((acct: any) => {
        let addr = acct.address
        items.push({
          label:
            acct.label +
            '(' +
            addr.substring(0, 4) +
            '...' +
            addr.substring(addr.length - 6, addr.length) +
            ')',
          key: acct.appearanceId.toString(),
          value: acct.appearanceId.toString(),
          sort: -1,
        })
      })
    }
    return items
  }

  const fetchTokenBalance = async (
    address: string,
    resourceAddress: string | undefined
  ) => {
    if (walletData.connected && address && resourceAddress) {
      const balanceResult = queryAssetBalance(rdt, address, resourceAddress)
      balanceResult.then((balance) => {
        setBalance(balance)
      })
    }
  }

  const fetchAssetMetaData = async (resourceAddress: string) => {
    if (walletData.connected && resourceAddress) {
      const result = queryAssetMetaData(rdt, resourceAddress)
      if (result !== undefined) {
        result.then((metaData) => {
          const typed = metaData?.items.find((item) => item.key === 'icon_url')
            ?.value.typed
          if (typed && (typed.type === 'Url'|| typed.type === 'String')) {
            setAssetIconUrl(typed.value)
          }
          const symbolTyped = metaData?.items.find(
            (item) => item.key === 'symbol'
          )?.value.typed
          if (symbolTyped && symbolTyped.type === 'String') {
            setAssetSymbol(symbolTyped.value)
          }
        })
      }
    }
  }

  const changeAccount = (value: string) => {
    setAccount(value)
    let address = walletData.walletDataState.accounts.find(
      (account: any) => account.appearanceId.toString() === value
    )?.address
    if (address !== undefined) {
      setAddress(address)
      fetchTokenBalance(address, token)
    }
  }

  const changeToken = (value: string) => {
    setToken(value)
    fetchTokenBalance(address, value)
  }

  const freshPrice = async () => {
    if (poolData && priceMap) {
      poolData.price = priceMap.get(poolData.symbol)
      poolData.priceInXrd = priceMap.get(poolData.symbol + '_in_XRD')
      poolData.tokenPrice = priceMap.get(poolData.symbol) / poolData.borrowIndex
      poolData.tokenPriceInXrd = priceMap.get(poolData.symbol + '_IN_XRD') / poolData.borrowIndex
    }
  }

  const queryData = async (tokenAddress: string | undefined) => {
    if (walletData.connected === false) {
      return
    }
    setOverviewStatus(OverviewStatusType.LOADING)

    if (!priceMap) {
      return
    }
    if (poolData) {
      freshPrice()
    } else {
      queryAssetStates(rdt, handleAssetStates)
    }
  }

  const handleAssetStates = async (assetStates:any)=>{
    const assetState = assetStates.find(
      (assetState:any) => assetState.address === token
    )
    if (assetState !== undefined) {
      const poolDataResult = genPoolDataByAssetSate(
        rdt,
        assetState,
        priceMap
      )
      
      poolDataResult.then((poolData) => {
        if (poolData !== undefined) {
          setPoolData(poolData)
          setOverviewStatus(OverviewStatusType.READY)
        }
      })
    }
  }

  const doSupply = async () => {
    setSubmitButtonStatus(SubmitButtonStatusType.PROCESS)
    let manifest = `
      CALL_METHOD
        Address("${address}")
        "withdraw"
        Address("${token}")
        Decimal("${inputValue}");

      TAKE_FROM_WORKTOP
        Address("${token}")
        Decimal("${inputValue}")
        Bucket("supplyBucket");

      CALL_METHOD
          Address("${CONFIG.LENDING_FACTORY_COMPONENT_ADDRESS}")
          "supply"
          Bucket("supplyBucket");
      CALL_METHOD
          Address("${address}")
          "deposit_batch"
          Expression("ENTIRE_WORKTOP");
    `

    console.log(manifest)
    const result = await rdt?.walletApi.sendTransaction({
      transactionManifest: manifest,
    })
    if (result?.isErr()) {
      setSubmitButtonStatus(SubmitButtonStatusType.READY)
      notificationApi.error({
        message: '',
        description: result.error.message,
        placement: 'bottomRight',
      })
      return
    }
    setSubmitButtonStatus(SubmitButtonStatusType.READY)
    fetchTokenBalance(address, token)

    let ret: any = result
    notificationApi.success({
      message: '',
      description: (
        <div style={{ marginTop: '16px' }}>
          <a
            target="_blank"
            rel="noreferrer"
            href={
              CONFIG.DASHBOARD_URL +
              ret.value.transactionIntentHash +
              '/details'
            }
          >
            {ret.value.transactionIntentHash}
          </a>
        </div>
      ),
      placement: 'bottomRight',
    })
    

    setOverviewStatus(OverviewStatusType.LOADING)
    queryAssetStates(rdt, handleAssetStates)
  }

  const onInputChange = (value: string) => {
    if (!walletData.connected) {
      return
    }
    setInputValue(value)

    if (value === '') {
      setSubmitButtonStatus(SubmitButtonStatusType.DISABLE)
    } else if (Number(value) > Number(balance)) {
      setSubmitButtonStatus(SubmitButtonStatusType.DISABLE)
      showAlert(
        'You cannot supply more collateral than the amount in your wallet'
      )
    } else {
      setSubmitButtonStatus(SubmitButtonStatusType.READY)
      hideAlert()
    }
  }

  const showAlert = (message: string) => {
    setAlertMessage(message)
    setIsShowAlert(true)
  }

  const hideAlert = () => {
    setAlertMessage('')
    setIsShowAlert(false)
  }

  return (
    <Layout>
      {contextHolder}
      <LayoutContainer>
        {/* <Intro>
        <IntroTitle>Dexian Lending Protocol</IntroTitle>
        <IntroContent>DeXian Protocol, bringing greater liquidity and more efficient trading experience to Radix.</IntroContent>
      </Intro>

      <SwitchButtons device={props.device}/> */}
        <NavigateButtons />

        <DataZone device={props.device}>
          <div>
            <OverviewZone>
              <BoxTitle>
                <BoxTitleContainer>
                  <BoxTitleContent>
                    {assetIconUrl !== '' ? (
                      <span>
                        <img
                          style={{ width: '24px', height: '24px' }}
                          src={assetIconUrl}
                          alt="icon"
                        ></img>{' '}
                      </span>
                    ) : (
                      <></>
                    )}
                    {assetSymbol !== '' ? assetSymbol + ' ' : ''}
                    {'Overview'}
                  </BoxTitleContent>
                </BoxTitleContainer>
              </BoxTitle>

              <BoxLayout>
                <OverviewData device={props.device}>
                  <OverviewDataItem
                    onClick={() => {
                      setIsSupplyRateModalOpen(true)
                    }}
                  >
                    <OverviewDataItemT>
                      Supply Rate{' '}
                      <Popover placement="topLeft" content="">
                        <QuestionCircleOutlined />
                      </Popover>
                    </OverviewDataItemT>
                    {overviewStatus === OverviewStatusType.LOADING ? (
                      <OverviewDataItemIcon>
                        <Loading3QuartersOutlined spin />
                      </OverviewDataItemIcon>
                    ) : (
                      <OverviewDataItemC>
                        {poolData === undefined
                          ? 'N/A'
                          : numberToFactor(poolData.supplyInterestRate)}
                      </OverviewDataItemC>
                    )}
                  </OverviewDataItem>
                  <OverviewDataItem
                    onClick={() => {
                      setIsBorrowRateModalOpen(true)
                    }}
                  >
                    <OverviewDataItemT>
                      Borrow Rate{' '}
                      <Popover placement="topLeft" content="">
                        <QuestionCircleOutlined />
                      </Popover>
                    </OverviewDataItemT>
                    {overviewStatus === OverviewStatusType.LOADING ? (
                      <OverviewDataItemIcon>
                        <Loading3QuartersOutlined spin />
                      </OverviewDataItemIcon>
                    ) : (
                      <OverviewDataItemC>
                        {poolData === undefined
                          ? 'N/A'
                          : numberToFactor(poolData.borrowInterestRate)}
                      </OverviewDataItemC>
                    )}
                  </OverviewDataItem>
                  <OverviewDataItem
                    onClick={() => {
                      setIsTotalSupplyModalOpen(true)
                    }}
                  >
                    <OverviewDataItemT>
                      Total Supply{' '}
                      <Popover placement="topLeft" content="">
                        <QuestionCircleOutlined />
                      </Popover>
                    </OverviewDataItemT>
                    {overviewStatus === OverviewStatusType.LOADING ? (
                      <OverviewDataItemIcon>
                        <Loading3QuartersOutlined spin />
                      </OverviewDataItemIcon>
                    ) : (
                      <OverviewDataItemC>
                        {poolData === undefined
                          ? 'N/A'
                          : poolData.tokenTotalSupply.toFixed(6)}
                      </OverviewDataItemC>
                    )}
                  </OverviewDataItem>
                  <OverviewDataItem
                    onClick={() => {
                      setIsUtilizationFactorModalOpen(true)
                    }}
                  >
                    <OverviewDataItemT>
                      Utilization Factor{' '}
                      <Popover placement="topLeft" content="">
                        <QuestionCircleOutlined />
                      </Popover>
                    </OverviewDataItemT>
                    {overviewStatus === OverviewStatusType.LOADING ? (
                      <OverviewDataItemIcon>
                        <Loading3QuartersOutlined spin />
                      </OverviewDataItemIcon>
                    ) : (
                      <OverviewDataItemC>
                        {poolData === undefined
                          ? 'N/A'
                          : numberToFactor(
                              getUtilizationFactorByPoolData(poolData)
                            )}
                      </OverviewDataItemC>
                    )}
                  </OverviewDataItem>
                  <OverviewDataItem
                    onClick={() => {
                      setIsAssetPriceModalOpen(true)
                    }}
                  >
                    <OverviewDataItemT>
                      Asset Price{' '}
                      <Popover placement="topLeft" content="">
                        <QuestionCircleOutlined />
                      </Popover>
                    </OverviewDataItemT>
                    {overviewStatus === OverviewStatusType.LOADING ? (
                      <OverviewDataItemIcon>
                        <Loading3QuartersOutlined spin />
                      </OverviewDataItemIcon>
                    ) : (
                      <OverviewDataItemC>
                        {poolData === undefined
                          ? 'N/A'
                          : numberToPrice(poolData.price, 6)}
                      </OverviewDataItemC>
                    )}
                  </OverviewDataItem>
                </OverviewData>
              </BoxLayout>
            </OverviewZone>
          </div>

          <div>
            <OptZone>
              <BoxTitle>
                <BoxTitleContainer>
                  <BoxTitleContent>Supply</BoxTitleContent>
                </BoxTitleContainer>
              </BoxTitle>

              <BoxLayout>
                <FormLine>
                  <FlexZone>
                    <FormLineTitle>Account</FormLineTitle>
                    <div style={{ width: '100%', paddingLeft: '16px' }}>
                      <AccountPicker
                        disable={walletData.connected ? false : true}
                        device={props.device}
                        onSelect={changeAccount}
                        defaultValue={account}
                        items={genAccountList()}
                      />
                    </div>
                  </FlexZone>
                </FormLine>
                <FlexZone>
                  <FlexZone>
                    <FormLineTitle>Amount</FormLineTitle>
                    <FormLineRight>
                      <div>
                        <NumericInput
                          style={{ width: '', height: 38 }}
                          onChange={onInputChange}
                          value={inputValue}
                          disabled={walletData.connected ? false : true}
                        />
                        {walletData.connected ? (
                          <div>
                            <span
                              style={{
                                fontSize: '14px',
                                color: '#003057',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                onInputChange(formatNumber(balance))
                              }}
                            >
                              {formatNumber(balance)}
                            </span>
                            <span
                              style={{ fontSize: '12px', color: '#8A8FA4' }}
                            >
                              (Available balance)
                            </span>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div>
                        <SelectComponent
                          height="32px"
                          device={props.device}
                          onSelect={changeToken}
                          defaultValue={token}
                          items={CONFIG.POOL_TOKENS}
                          disable={true}
                        />
                      </div>
                    </FormLineRight>
                  </FlexZone>
                </FlexZone>
                {isShowAlert ? (
                  <Alert
                    style={{ marginTop: '8px' }}
                    message={alertMessage}
                    type="error"
                  />
                ) : (
                  <></>
                )}
              </BoxLayout>
            </OptZone>

            <SubmitWrapper>
              <SubmitButton
                status={submitButtonStatus}
                disabled={
                  submitButtonStatus === SubmitButtonStatusType.DISABLE ||
                  submitButtonStatus === SubmitButtonStatusType.PROCESS
                    ? true
                    : false
                }
                onClick={doSupply}
              >
                {submitButtonStatus === SubmitButtonStatusType.READY ||
                submitButtonStatus === SubmitButtonStatusType.DISABLE ? (
                  'Supply'
                ) : (
                  <Spin
                    indicator={
                      <Loading3QuartersOutlined
                        style={{ fontSize: '37px', color: '#FFF' }}
                        spin
                      />
                    }
                  ></Spin>
                )}
              </SubmitButton>
            </SubmitWrapper>
          </div>
        </DataZone>
        <Modal
          title="Supply Rate"
          maskClosable={true}
          open={isSupplyRateModalOpen}
          onCancel={() => {
            setIsSupplyRateModalOpen(false)
          }}
          footer={null}
        >
          <p>The current supply annual interest rate</p>
        </Modal>

        <Modal
          title="Borrow Rate"
          maskClosable={true}
          open={isBorrowRateModalOpen}
          onCancel={() => {
            setIsBorrowRateModalOpen(false)
          }}
          footer={null}
        >
          <p>The current borrow annual interest rate</p>
        </Modal>

        <Modal
          title="Total Supply"
          maskClosable={true}
          open={isTotalSupplyModalOpen}
          onCancel={() => {
            setIsTotalSupplyModalOpen(false)
          }}
          footer={null}
        >
          <p>Current balance of total assets in this pool</p>
        </Modal>

        <Modal
          title="Asset Price"
          maskClosable={true}
          open={isAssetPriceModalOpen}
          onCancel={() => {
            setIsAssetPriceModalOpen(false)
          }}
          footer={null}
        >
          <p>
            The current price of the asset in USDT, a price that currently comes
            from oracle
          </p>
        </Modal>

        <Modal
          title="Utilization factor"
          maskClosable={true}
          open={isUtilizationFactorModalOpen}
          onCancel={() => {
            setIsUtilizationFactorModalOpen(false)
          }}
          footer={null}
        >
          <p>Average borrowing rate</p>
        </Modal>
      </LayoutContainer>
    </Layout>
  )
}

// Layout
const Layout = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
  height: 100%;
  display: flex;
`
const LayoutContainer = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex: 1 1 0%;
  position: relative;
`

// intro
// Data zone
const DataZone = styled.div`
  gap: ${(props: { device: String }) =>
    props.device === 'mobile' ? '24px' : '48px'};
  grid-template-columns: ${(props: { device: String }) =>
    props.device === 'mobile' ? '2f4' : '2fr minmax(465px, 1fr)'};
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: grid;
  align-items: flex-start;
`
const OverviewZone = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 0px;
  border: 1px solid rgb(240, 240, 240);
  margin-top: 20px;
`
const BoxTitle = styled.div`
  box-sizing: border-box;
  margin: 0px 16px;
  min-width: 0px;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgb(240, 240, 240);
  display: flex;
`
const BoxTitleContainer = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  -webkit-box-align: center;
  align-items: center;
  height: 40px;
  display: flex;
`
const BoxTitleContent = styled.p`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-family: Inter, 'Helvetica Neue', sans-serif;
  font-weight: 600;
  line-height: 24px;
  font-size: 16px;
  color: #25273d;
`
const BoxLayout = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  padding: 24px 16px;
`
const OverviewData = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: grid;
  gap: 16px;
  grid-template-columns: ${(props: { device: String }) =>
    props.device === 'mobile' ? '1fr' : '1fr 1fr'};
`
const OverviewDataItem = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  border-radius: 8px;
  background-color: #ffffff;
  transition: background-color 200ms ease 0s;
  overflow-wrap: break-word;
  display: flex;
  cursor: pointer;
  :hover {
    background-color: #f3f7f9;
  }
`
const OverviewDataItemT = styled.h3`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-family: Inter, 'Helvetica Neue', sans-serif;
  font-weight: 600;
  line-height: 20px;
  font-size: 12px;
  color: #787a9b;
`
const OverviewDataItemC = styled.p`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-family: __FTPolarMedium_6973ac, __FTPolarMedium_Fallback_6973ac,
    'Helvetica Neue', sans-serif;
  font-weight: 500;
  font-size: 32px;
  color: #25273d;
  max-width: 100%;
  line-height: 1.35;
`
const OverviewDataItemIcon = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-family: __FTPolarMedium_6973ac, __FTPolarMedium_Fallback_6973ac,
    'Helvetica Neue', sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: #8a8fa4;
  max-width: 100%;
  line-height: 1.35;
`
const OptZone = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  border-radius: 20px;
  background-color: #ffffff;
  position: relative;
  padding: 0px;
  border: 1px solid rgb(240, 240, 240);
  margin-top: 20px;
`

const FlexZone = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const FormLine = styled(FlexZone)`
  margin-bottom: 8px;
`

const FormLineTitle = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 80px;
  // padding-right: 16px;
  // margin-right: 16px;
  text-align: right;
`
const FormLineRight = styled.div`
  display: grid;
  width: 100%;
  padding-left: 16px;
  grid-template-columns: 2fr 1fr;
  gap: 17px;
`

// submit
const SubmitWrapper = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  display: grid;
  gap: 16px;
  padding: 24px;
  // border-top: 1px solid rgb(240, 240, 240);
`
// background-color: var(--theme-ui-colors-neutral60,#BEC9D0);
const SubmitButton = styled.button`
  // pointer-events: none;
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  appearance: none;
  text-align: center;
  text-decoration: none;
  padding: 8px 16px;
  background-color: ${(props: { status: SubmitButtonStatusType }) =>
    props.status === SubmitButtonStatusType.DISABLE ||
    props.status === SubmitButtonStatusType.PROCESS
      ? '#787A9B'
      : '#25273D'};
  border: 0px;
  font-family: Inter, 'Helvetica Neue', sans-serif;
  line-height: 2.1em;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
  font-weight: 600;
  border-radius: 32px;
  transition: background 200ms ease 0s;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  :hover {
    background-color: #787a9b;
  }
`

export default Supply
