import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { CDPData, PoolData } from '../component/Types'
import {
  formatNumber,
  genLoanToValue,
  genAvailibleToGenerate,
  genCollateralLockedTotalValue,
  genCdpLoanToValue,
  getLiquidationPrice,
  getUtilizationFactorByPoolData,
  numberToFactor,
  numberToPrice,
  getBellowCdpLiqudationPrice,
  genAvaliableToWithdraw,
  genVaultDebt,
} from '../utils/ContractDataUtil'
// import { DEPOSIT_TOKENS, XRD_ADDRESS } from '../constants/cfg';
// import CONFIG from '../env/index'
import { useNavigate } from 'react-router-dom'
import { Popover, Modal } from 'antd'
import { getDxTokenAddress } from '../utils/ContractDataUtil'
import {
  QuestionCircleOutlined,
  Loading3QuartersOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import { InternalSymbolName } from 'typescript'
import Loading from './Loading'

const CdpCard: React.FC<{
  device: String
  data: CDPData | undefined
  poolArray: PoolData[]
  address: string
  from: string
  opt: string | null
  depositToken: string
  depositValue: number
  borrowToken: string
  borrowValue: number
}> = (props) => {
  const navigate = useNavigate()

  useEffect(()=>{
    // console.log(props)
  },[props])

  const [isLiquidationPriceModalOpen, setIsLiquidationPriceModalOpen] =
    useState(false)
  const [
    isCollateralizationRatioModalOpen,
    setIsCollateralizationRatioModalOpen,
  ] = useState(false)
  const [isCollateralLockedModalOpen, setIsCollateralLockedModalOpen] =
    useState(false)
  const [isLoanToValueModalOpen, setIsLoanToValueModalOpen] = useState(false)

  const genLiquidationPriceAfter = () => {
    if (props.data != undefined && props.poolArray != undefined) {
      if (props.depositValue > 0 || props.borrowValue > 0) {
        let price: number = 0
        if (props.opt === null) {
          return 0
        }
        switch (props.opt) {
          case 'deposit':
            price = getLiquidationPrice(
              props.poolArray,
              props.data,
              props.depositToken,
              props.depositValue,
              props.data.borrowToken,
              0
            )
            break
          case 'withdraw':
            price = getLiquidationPrice(
              props.poolArray,
              props.data,
              props.depositToken,
              -props.depositValue,
              props.data.borrowToken,
              0
            )
            break
          case 'generate':
            price = getLiquidationPrice(
              props.poolArray,
              props.data,
              props.depositToken,
              0,
              props.borrowToken,
              props.borrowValue
            )
            break
          case 'repay':
            price = getLiquidationPrice(
              props.poolArray,
              props.data,
              props.depositToken,
              0,
              props.borrowToken,
              -props.borrowValue
            )
            break
          default:
            price = 0
        }
        return (
          <OverviewAfterDataDiv>
            <OverviewAfterDataP>
              {numberToPrice(price)} After
            </OverviewAfterDataP>
          </OverviewAfterDataDiv>
        )
      }
    } else {
      return <></>
    }
  }

  const genCollateralRatioAfter = () => {
    if (props.data != undefined && props.poolArray != undefined) {
      if (props.depositValue > 0 || props.borrowValue > 0) {
        let value: number = 0
        if (props.opt === null) {
          return 0
        }
        switch (props.opt) {
          case 'deposit':
            value = genLoanToValue(
              props.poolArray,
              props.data,
              props.depositToken,
              props.depositValue,
              props.data.borrowToken,
              0
            )
            break
          case 'withdraw':
            value = genLoanToValue(
              props.poolArray,
              props.data,
              props.depositToken,
              -props.depositValue,
              props.data.borrowToken,
              0
            )
            break
          case 'generate':
            value = genLoanToValue(
              props.poolArray,
              props.data,
              props.depositToken,
              0,
              props.borrowToken,
              props.borrowValue
            )
            break
          case 'repay':
            value = genLoanToValue(
              props.poolArray,
              props.data,
              props.depositToken,
              0,
              props.borrowToken,
              -props.borrowValue
            )
            break
          default:
            value = 0
        }
        return (
          <OverviewAfterDataDiv>
            <OverviewAfterDataP>
              {numberToFactor(value)} After
            </OverviewAfterDataP>
          </OverviewAfterDataDiv>
        )
      }
    } else {
      return <></>
    }
  }

  const genCollateralLockedAfter = () => {
    if (props.data != undefined && props.poolArray != undefined) {
      if (props.depositValue > 0 || props.borrowValue > 0) {
        let value: number = 0
        if (props.opt === null) {
          return 0
        }
        switch (props.opt) {
          case 'deposit':
            value = genCollateralLockedTotalValue(
              props.poolArray,
              props.data,
              props.depositToken,
              props.depositValue,
              props.borrowToken,
              0
            )
            break
          case 'withdraw':
            value = genCollateralLockedTotalValue(
              props.poolArray,
              props.data,
              props.depositToken,
              -props.depositValue,
              props.borrowToken,
              0
            )
            break
          case 'generate':
            value = genCollateralLockedTotalValue(
              props.poolArray,
              props.data,
              props.depositToken,
              0,
              props.borrowToken,
              props.borrowValue
            )
            break
          case 'repay':
            value = genCollateralLockedTotalValue(
              props.poolArray,
              props.data,
              props.depositToken,
              0,
              props.borrowToken,
              -props.borrowValue
            )
            break
          default:
            value = 0
        }
        return (
          <OverviewAfterDataDiv>
            <OverviewAfterDataP>
              {numberToPrice(value)} After
            </OverviewAfterDataP>
          </OverviewAfterDataDiv>
        )
      }
    } else {
      return <></>
    }
  }

  const genVaultDebtAfter = () => {
    if (props.data != undefined && props.poolArray != undefined) {
      if (props.depositValue > 0 || props.borrowValue > 0) {
        let value: number = 0
        if (props.opt === null) {
          return 0
        }
        switch (props.opt) {
          case 'deposit':
            value = genVaultDebt(
              props.poolArray,
              props.data,
              props.borrowToken,
              0
            )
            break
          case 'withdraw':
            value = genVaultDebt(
              props.poolArray,
              props.data,
              props.borrowToken,
              0
            )
            break
          case 'generate':
            value = genVaultDebt(
              props.poolArray,
              props.data,
              props.borrowToken,
              props.borrowValue
            )
            break
          case 'repay':
            value = genVaultDebt(
              props.poolArray,
              props.data,
              props.borrowToken,
              -props.borrowValue
            )
            break
          default:
            value = 0
        }
        return (
          <OverviewAfterDataDiv>
            <OverviewAfterDataP>
              {Number(value.toFixed(4))} {props.data.borrowTokenSymbol} After
            </OverviewAfterDataP>
          </OverviewAfterDataDiv>
        )
      }
    } else {
      return <></>
    }
  }

  const genAvailableToWithdrawAfter = () => {
    if (props.data != undefined && props.poolArray != undefined) {
      if (props.depositValue > 0 || props.borrowValue > 0) {
        let value = 0
        if (props.opt === null) {
          return 0
        }
        switch (props.opt) {
          case 'deposit':
            value = genAvaliableToWithdraw(
              props.poolArray,
              props.data,
              props.depositToken,
              props.depositValue,
              props.borrowToken,
              0
            )
            break
          case 'withdraw':
            value = genAvaliableToWithdraw(
              props.poolArray,
              props.data,
              props.depositToken,
              -props.depositValue,
              props.borrowToken,
              0
            )
            break
          case 'generate':
            value = genAvaliableToWithdraw(
              props.poolArray,
              props.data,
              props.depositToken,
              0,
              props.borrowToken,
              props.borrowValue
            )
            break
          case 'repay':
            value = genAvaliableToWithdraw(
              props.poolArray,
              props.data,
              props.depositToken,
              0,
              props.borrowToken,
              -props.borrowValue
            )
            break
          default:
            value = 0
        }
        return (
          <OverviewAfterDataDiv>
            <OverviewAfterDataP>
              {value.toFixed(4)}{' '}
              {
                props.poolArray.find(
                  (item) => item.address === props.depositToken
                )?.symbol
              }{' '}
              After
            </OverviewAfterDataP>
          </OverviewAfterDataDiv>
        )
      }
    } else {
      return <></>
    }
  }

  const genAvailableToGenerateAfter = () => {
    if (props.data != undefined && props.poolArray != undefined) {
      if (props.depositValue > 0 || props.borrowValue > 0) {
        let value = 0
        if (props.opt === null) {
          return 0
        }
        switch (props.opt) {
          case 'deposit':
            value = genAvailibleToGenerate(
              props.poolArray,
              props.data,
              props.depositToken,
              props.depositValue,
              props.borrowToken,
              0
            )
            break
          case 'withdraw':
            value = genAvailibleToGenerate(
              props.poolArray,
              props.data,
              props.depositToken,
              -props.depositValue,
              props.borrowToken,
              0
            )
            break
          case 'generate':
            value = genAvailibleToGenerate(
              props.poolArray,
              props.data,
              props.depositToken,
              0,
              props.borrowToken,
              props.borrowValue
            )
            break
          case 'repay':
            value = genAvailibleToGenerate(
              props.poolArray,
              props.data,
              props.depositToken,
              0,
              props.borrowToken,
              -props.borrowValue
            )
            break
          default:
            value = 0
        }
        return (
          <OverviewAfterDataDiv>
            <OverviewAfterDataP>
              {value.toFixed(4)} {props.data.borrowTokenSymbol} After
            </OverviewAfterDataP>
          </OverviewAfterDataDiv>
        )
      }
    } else {
      return <></>
    }
  }
  return (
    <CdpCardContainer>
      <CdpCardHeader>
        {props.data === undefined ? (
          <div>Overview</div>
        ) : (
          <div>
            {props.data.nonFungibleId} ({props.data.collateralTokenSymbol}/
            {props.data.borrowTokenSymbol})
          </div>
        )}
      </CdpCardHeader>
    
        <CdpCardContent>
          <CdpCardContentGrid device={props.device}>
            {props.data === undefined || props.poolArray.length === 0 ? (
              <CdpCardContentItem>
                <CdpCardContentItemT>
                  Liquidation Price{' '}
                  <QuestionCircleOutlined style={{ marginLeft: '2px' }} />
                </CdpCardContentItemT>
                <OverviewDataItemIcon>
                  <Loading3QuartersOutlined spin />
                </OverviewDataItemIcon>
              </CdpCardContentItem>
            ) : (
              <CdpCardContentItem
                onClick={() => {
                  setIsLiquidationPriceModalOpen(true)
                }}
              >
                <CdpCardContentItemT>
                  Liquidation Price{' '}
                  <QuestionCircleOutlined style={{ marginLeft: '2px' }} />
                </CdpCardContentItemT>
                <CdpCardContentItemC>
                  $
                  {getLiquidationPrice(
                    props.poolArray,
                    props.data,
                    props.depositToken,
                    0,
                    props.borrowToken,
                    0
                  ).toFixed(4)}
                </CdpCardContentItemC>
                {genLiquidationPriceAfter()}
                <CdpCardContentAfter>
                  {numberToFactor(
                    getBellowCdpLiqudationPrice(
                      props.poolArray,
                      props.data,
                      props.depositToken
                    ),
                    2
                  )}{' '}
                  below current price
                </CdpCardContentAfter>
              </CdpCardContentItem>
            )}

            {props.data === undefined || props.poolArray.length === 0 ? (
              <CdpCardContentItem>
                <CdpCardContentItemT>
                  Collateralization Ratio
                  <QuestionCircleOutlined style={{ marginLeft: '2px' }} />
                </CdpCardContentItemT>
                <OverviewDataItemIcon>
                  <Loading3QuartersOutlined spin />
                </OverviewDataItemIcon>
              </CdpCardContentItem>
            ) : (
              <CdpCardContentItem
                onClick={() => {
                  setIsCollateralizationRatioModalOpen(true)
                }}
              >
                <CdpCardContentItemT>
                  Collateralization Ratio
                  <QuestionCircleOutlined style={{ marginLeft: '2px' }} />
                </CdpCardContentItemT>
                <CdpCardContentItemC>
                  {numberToFactor(
                    genLoanToValue(
                      props.poolArray,
                      props.data,
                      props.depositToken,
                      0,
                      props.borrowToken,
                      0
                    ),
                    2
                  )}
                </CdpCardContentItemC>
                {genCollateralRatioAfter()}
              </CdpCardContentItem>
            )}

            {props.data === undefined || props.poolArray.length === 0 ? (
              <CdpCardContentItem>
                <CdpCardContentItemT>
                  Collateral Locked
                  <QuestionCircleOutlined style={{ marginLeft: '2px' }} />
                </CdpCardContentItemT>
                <OverviewDataItemIcon>
                  <Loading3QuartersOutlined spin />
                </OverviewDataItemIcon>
              </CdpCardContentItem>
            ) : (
              <CdpCardContentItem
                onClick={() => {
                  setIsCollateralLockedModalOpen(true)
                }}
              >
                <CdpCardContentItemT>
                  Collateral Locked
                  <QuestionCircleOutlined style={{ marginLeft: '2px' }} />
                </CdpCardContentItemT>
                <CdpCardContentItemC>
                  {numberToPrice(
                    genCollateralLockedTotalValue(props.poolArray, props.data),
                    2
                  )}
                </CdpCardContentItemC>
                {genCollateralLockedAfter()}
                <CdpCardContentAfter>
                  {Number(props.data.collateralAmount).toFixed(8)}{' '}
                  {props.data.collateralTokenSymbol}{' '}
                </CdpCardContentAfter>
              </CdpCardContentItem>
            )}
          </CdpCardContentGrid>
        </CdpCardContent>
      
      <BottomBoxLayout>
        <BottomCdpCardContentGrid device={props.device}>
          {props.data === undefined || props.poolArray.length === 0 ? (
            <CdpCardContentItemBottom>
              <CdpCardContentItemT>Vault Debt</CdpCardContentItemT>
              <OverviewDataItemIcon>
                <Loading3QuartersOutlined spin />
              </OverviewDataItemIcon>
            </CdpCardContentItemBottom>
          ) : (
            <CdpCardContentItemBottom>
              <CdpCardContentItemT>
                Vault {props.data.borrowTokenSymbol} Debt
              </CdpCardContentItemT>
              <CdpCardContentItemC style={{ fontSize: '16px' }}>
                {formatNumber(
                  genVaultDebt(
                    props.poolArray,
                    props.data,
                    props.data.borrowToken,
                    0
                  )
                )}{' '}
                {props.data.borrowTokenSymbol}
              </CdpCardContentItemC>
              {genVaultDebtAfter()}
            </CdpCardContentItemBottom>
          )}

          {props.data === undefined || props.poolArray.length === 0 ? (
            <CdpCardContentItemBottom>
              <CdpCardContentItemT>Available to Withdraw</CdpCardContentItemT>
              <OverviewDataItemIcon>
                <Loading3QuartersOutlined spin />
              </OverviewDataItemIcon>
            </CdpCardContentItemBottom>
          ) : (
            <CdpCardContentItemBottom>
              <CdpCardContentItemT>Available to Withdraw</CdpCardContentItemT>
              <CdpCardContentItemC style={{ fontSize: '16px' }}>
                {genAvaliableToWithdraw(
                  props.poolArray,
                  props.data,
                  props.depositToken,
                  0,
                  props.borrowToken,
                  0
                ).toFixed(6)}{' '}
                {
                  props.poolArray.find(
                    (item) => item.token === props.data?.collateralToken
                  )?.symbol
                }
              </CdpCardContentItemC>
              {genAvailableToWithdrawAfter()}
            </CdpCardContentItemBottom>
          )}

          {props.data === undefined || props.poolArray.length === 0 ? (
            <CdpCardContentItemBottom>
              <CdpCardContentItemT>Available to Generate</CdpCardContentItemT>
              <OverviewDataItemIcon>
                <Loading3QuartersOutlined spin />
              </OverviewDataItemIcon>
            </CdpCardContentItemBottom>
          ) : (
            <CdpCardContentItemBottom>
              <CdpCardContentItemT>Available to Generate</CdpCardContentItemT>
              <CdpCardContentItemC style={{ fontSize: '16px' }}>
                {genAvailibleToGenerate(props.poolArray, props.data).toFixed(4)}{' '}
                {props.data.borrowTokenSymbol}
              </CdpCardContentItemC>
              {genAvailableToGenerateAfter()}
            </CdpCardContentItemBottom>
          )}
        </BottomCdpCardContentGrid>
      </BottomBoxLayout>
      {props.from === 'list' && props.data !== undefined ? (
        <SubmitButtonArea>
          <SubmitButton
            onClick={() => {
              navigate(
                '/manage/' +
                  props.address +
                  '/' +
                  props.data?.nonFungibleId.replaceAll('#', '')
              )
            }}
          >
            Manage
          </SubmitButton>
        </SubmitButtonArea>
      ) : (
        <></>
      )}

      <Modal
        title="Liquidation Price"
        maskClosable={true}
        open={isLiquidationPriceModalOpen}
        onCancel={() => {
          setIsLiquidationPriceModalOpen(false)
        }}
        footer={null}
      >
        <p>
          The Liquidation Price is the price at which a Vault becomes vulnerable
          to liquidation
        </p>
      </Modal>

      <Modal
        title="Collateralization Ratio"
        maskClosable={true}
        open={isCollateralizationRatioModalOpen}
        onCancel={() => {
          setIsCollateralizationRatioModalOpen(false)
        }}
        footer={null}
      >
        <p>
          Your collateralization ratio is calculated by: value of your
          collateral / debt.
        </p>
        <p>
          The price used to calculate the value of your collateral is the price
          obtained from the Maker oracles. Your collateralization ratio needs to
          be above the minimum required at all times to not get liquidated.
        </p>
      </Modal>

      <Modal
        title="Collateral Locked"
        maskClosable={true}
        open={isCollateralLockedModalOpen}
        onCancel={() => {
          setIsCollateralLockedModalOpen(false)
        }}
        footer={null}
      >
        <p>The total amount of collateral locked in your vault.</p>
      </Modal>

      <Modal
        title="Loan To Value"
        maskClosable={true}
        open={isLoanToValueModalOpen}
        onCancel={() => {
          setIsLoanToValueModalOpen(false)
        }}
        footer={null}
      >
        <p>
          This is a financial term to express the ratio of a loan to the value
          of an asset purchased.{' '}
        </p>
        <p>
          If this value is equal to 0, it means that the collateral does not
          support the loan
        </p>
      </Modal>
    </CdpCardContainer>
  )
}

const CdpCardContainer = styled.div`
  box-sizing: border-box;
  margin: 20px 0px 0px 0px;
  min-width: 0px;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 0px;
  border: 1px solid rgb(240, 240, 240);
  :hover {
    border: 1px solid #5b978e;
  }
`

const CdpCardHeader = styled.div`
  display: flex;
  box-sizing: border-box;
  margin: 0px 16px;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgb(240, 240, 240);
`

const CdpCardContent = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  margin: 0 16px;
  // border-bottom: 1px solid rgb(240, 240, 240);
`

const CdpCardContentGrid = styled.div`
  box-sizing: border-box;
  display: grid;
  gap: 16px;
  grid-template-columns: ${(props: { device: String }) =>
    props.device === 'mobile' ? '1fr' : '1fr 1fr'};
`

const BottomCdpCardContentGrid = styled.div`
  box-sizing: border-box;
  display: grid;
  gap: 16px;
  grid-template-columns: ${(props: { device: String }) =>
    props.device === 'mobile' ? '1fr' : '1fr 1fr 1fr'};
`

const CdpCardContentItemBottom = styled.div`
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  cursor: pointer;
  align-items: flex-start;
  border-radius: 8px;
`

const CdpCardContentItem = styled.div`
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  cursor: pointer;
  align-items: flex-start;
  border-radius: 8px;
  :hover {
    background-color: #f3f7f9;
  }
`

const CdpCardContentItemT = styled.p`
  box-sizing: border-box;
  font-family: Inter, 'Helvetica Neue', sans-serif;
  font-weight: 400;
  font-size: 11px;
  color: #787a9b;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
`

const CdpCardContentItemC = styled.span`
  box-sizing: border-box;
  // margin: 0px 8px;
  min-width: 0px;
  font-weight: 400;
  font-size: 32px;
  color: #25273d;
  max-width: 100%;
  line-height: 1.35;
`

const CdpCardContentAfter = styled.span`
  box-sizing: border-box;
  // margin: 0px 8px;
  min-width: 0px;
  font-weight: 600;
  font-size: 12px;
  padding-top: 8px;
  color: #25273d;
  max-width: 100%;
  line-height: 1.35;
`

const CdpCardContentItemFactor = styled.span`
  box-sizing: border-box;
  // margin: 0px 8px;
  min-width: 0px;
  font-weight: 400;
  font-size: 32px;
  color: #5b978e;
  max-width: 100%;
  line-height: 1.35;
`

const FaucetButton = styled.button`
  background-color: 91, 151, 142;
  border-radius: 8px;
  font-weight: 400;
  border: none;
  color: #5b978e;
  cursor: pointer;
`

const SubmitButtonArea = styled.div`
  display: flex;
  padding-top: 18px;
  padding-bottom: 18px;
`

const SubmitButton = styled.button`
  box-sizing: border-box;
  margin: 0px 6px;
  min-width: 0px;
  appearance: none;
  text-align: center;
  text-decoration: none;
  padding: 8px 16px;
  background-color: #25273d;
  border: 0px;
  font-family: Inter, 'Helvetica Neue', sans-serif;
  line-height: 2.1em;
  font-size: 12px;
  color: #ffffff;
  cursor: pointer;
  font-weight: 600;
  border-radius: 32px;
  transition: background 200ms ease 0s;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  :hover {
    background-color: #787a9b;
  }
`
const BottomBoxLayout = styled.div`
  border-top: 1px solid rgb(240, 240, 240);
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  padding: 24px 16px;
`

const OverviewDataItemIcon = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-family: __FTPolarMedium_6973ac, __FTPolarMedium_Fallback_6973ac,
    'Helvetica Neue', sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: #8a8fa4;
  max-width: 100%;
  line-height: 1.35;
`

const OverviewAfterDataDiv = styled.div`
  padding-top: 8px;
`
const OverviewAfterDataP = styled.p`
  color: #1aab9b;
  background-color: #e7fcfa;
  border-radius: 12px;
  padding: 4px 16px;
  font-size: 12px;
  width: 100%;
`

export default CdpCard
