import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useParams, Navigate } from 'react-router-dom'
import NavigateButtons from '../component/NavigateButtons'
import { Divider, Typography, Card, List, Button, notification } from 'antd'
import { CDPData, PoolData } from '../component/Types'
import { SelectListItem } from '../component/SelectComponent'
import { useRdt } from '../hooks/useRdt'
import {
  genOldCdpArray,
  queryAssetBalance,
  genOldPoolDataByAssetSate,
  queryOldAssetStates,
  genManifestPriceParams,
  getCurrentIndexByPoolData,
  getStableCurrentIndex,
  genMaxBorrowByDeposit,
  genMaxRepayAmount,
  queryCurrentEpoch,
} from '../utils/ContractDataUtil'
import AccountPicker from '../component/AccountPicker'
import Loading from '../component/Loading'
import CONFIG from '../env'
import { formatNumber } from '../utils/ContractDataUtil'

const { Title, Paragraph, Text, Link } = Typography

interface Props {
  device: string
}

enum CDPListStatus {
  INIT,
  READY,
}

const Transfer: React.FC<Props> = (props) => {
  const [api, contextHolder] = notification.useNotification()
  type NotificationType = 'success' | 'info' | 'warning' | 'error'
  const walletData = useSelector((state: any) => state.Wallet)
  const priceMap = useSelector((state: any) => state.Common.price_map)
  const common = useSelector((state: any) => state.Common)
  const rdt = useRdt()
  const [status, setStatus] = useState<CDPListStatus>(0)
  const [buttonStatus, setButtonStatus] = useState(0)
  const [account, setAccount] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [cdpArray, setCdpArray] = useState<CDPData[]>([])
  const [poolArray, setPoolArray] = useState<
    { symbol: string; balance: string; amount: number; address: string }[]
  >([])
  const [poolDataArray, setPoolDataArray] = useState<PoolData[]>([])

  useEffect(() => {
    initData()
  }, [walletData.connected, priceMap])

  const initData = async () => {
    if (walletData.connected) {
      if (!priceMap) {
        return
      }
      if (poolDataArray.length > 0) {
        freshPrice()
      } else {
        setAccount(
          walletData.walletDataState.accounts[0].appearanceId.toString()
        )
        walletData.walletDataState.accounts.forEach((acct: any) => {
          if (
            acct.appearanceId.toString() ===
            walletData.walletDataState.accounts[0].appearanceId.toString()
          ) {
            setAddress(acct.address)
            queryData(acct.address)
          }
        })
      }
    }
  }

  const freshPrice = () => {
    if (priceMap && poolDataArray) {
      poolDataArray.forEach((item) => {
        item.price = priceMap.get(item.symbol)
        item.priceInXrd = priceMap.get(item.symbol + '_IN_XRD')
        item.tokenPrice = priceMap.get(item.symbol) / item.borrowIndex
        item.tokenPriceInXrd =
          priceMap.get(item.symbol + '_IN_XRD') / item.borrowIndex
      })
    }
  }

  const queryData = async (address: string) => {
    setStatus(CDPListStatus.INIT)
    if (walletData.connected === false) {
      return
    }
    genOldCdpArray(rdt, address, handleCdpArray)
    queryPoolData(address)
    queryOldAssetStates(rdt, handleAssetStates)
  }

  const handleCdpArray = async (cdpArray: CDPData[]) => {
    setCdpArray(cdpArray)
  }

  const handleAssetStates = async (assetStates: any) => {
    var taskWatcher = assetStates.length
    let dataArray: PoolData[] = []

    assetStates.forEach((assetState: any) => {
      const poolDataResult = genOldPoolDataByAssetSate(
        rdt,
        assetState,
        priceMap
      )
      poolDataResult.then((poolData) => {
        if (poolData !== undefined) {
          dataArray?.push(poolData)
          taskWatcher--
          dataArray.sort((a, b) => a.sort - b.sort)
        }
        if (taskWatcher === 0) {
          setPoolDataArray(dataArray)
          setStatus(1)
        }
      })
    })
  }

  const queryPoolData = async (address: string) => {
    let array = []
    for (var item of CONFIG.OLD_ENV.DEPOSIT_TOKENS) {
      let i: any = item
      let amt = await queryAssetBalance(rdt, address, i.value)
      array.push({
        symbol: i.label,
        balance: formatNumber(amt),
        amount: amt,
        address: i.value,
      })
    }
    setPoolArray(array)
    setStatus(CDPListStatus.READY)
  }

  const changeAccount = (value: string) => {
    setAccount(value)
    walletData.walletDataState.accounts.forEach((acct: any) => {
      if (acct.appearanceId.toString() === value) {
        setAddress(acct.address)
        queryData(acct.address)
      }
    })
  }

  const genAccountList = () => {
    var items: SelectListItem[] = []
    if (walletData.connected) {
      walletData.walletDataState.accounts.forEach((acct: any) => {
        let addr = acct.address
        items.push({
          label:
            acct.label +
            '(' +
            addr.substring(0, 4) +
            '...' +
            addr.substring(addr.length - 6, addr.length) +
            ')',
          key: acct.appearanceId.toString(),
          value: acct.appearanceId.toString(),
          sort: -1,
        })
      })
    }
    return items
  }

  const migrateCdp = async (cdp: CDPData) => {
    console.log(cdp)

    let repay_amount = genMaxRepayAmount(poolDataArray, cdp)
    let cdp_id = cdp.nonFungibleId.replaceAll('#', '')
    let withdraw_amount = cdp.collateralAmount
    let multiplier = Math.pow(10, 6)
    let v = Math.floor(withdraw_amount * multiplier) / multiplier

    let params = genManifestPriceParams(
      'extend_borrow',
      '',
      '',
      CONFIG.OLD_ENV,
      common,
      cdp
    )
    // let params = genManifestPriceParams("extend_borrow", "", "", CONFIG, common, cdpData);
    const price2Str =
      params.price2 === 'None' ? `Enum<0u8>()` : `"${params.price2}"`
    const quote2Str =
      params.quote2 === 'None' ? `Enum<0u8>()` : `"${params.quote2}"`
    const timestamp2Str =
      params.timestamp2 === 'None' ? `Enum<0u8>()` : `${params.timestamp2}u64`
    const signature2Str =
      params.signature2 === 'None' ? `Enum<0u8>()` : `"${params.signature2}"`

    let manifest = `
          CALL_METHOD
            Address("${address}")
            "withdraw"
            Address("${cdp.borrowToken}")
            Decimal("${repay_amount}")
          ;
          CALL_METHOD
            Address("${address}")
            "withdraw_non_fungibles"
            Address("${CONFIG.OLD_ENV.CDP_ADDRESS}")
            Array<NonFungibleLocalId>(
                NonFungibleLocalId("#${cdp_id}#")
            )
          ;
          TAKE_FROM_WORKTOP
            Address("${cdp.borrowToken}")
            Decimal("${repay_amount}")
            Bucket("bucket1")
          ;
          TAKE_ALL_FROM_WORKTOP
            Address("${CONFIG.OLD_ENV.CDP_ADDRESS}")
            Bucket("bucket2")
          ;
          CALL_METHOD
              Address("${CONFIG.OLD_ENV.LENDING_FACTORY_COMPONENT_ADDRESS}")
              "repay"
              Bucket("bucket1")
              ${cdp_id}u64
          ;
          CALL_METHOD
              Address("${CONFIG.OLD_ENV.LENDING_FACTORY_COMPONENT_ADDRESS}")
              "withdraw_collateral"
              Bucket("bucket2")
              Decimal("${withdraw_amount}")
              "${params.price1}"
              Address("${params.quote1}")
              ${params.timestamp1}u64
              "${params.signature1}"
              ${price2Str} 
              ${quote2Str}
              ${timestamp2Str}
              ${signature2Str}
          ;
          CALL_METHOD
              Address("${address}")
              "deposit_batch"
              Expression("ENTIRE_WORKTOP")
          ;
    `
    console.log(manifest)
    const result = await rdt?.walletApi.sendTransaction({
      transactionManifest: manifest,
    })

    setButtonStatus(0)

    if (result?.isErr()) {
      api.error({
        message: 'error',
        description: result.error.message,
        duration: null,
        // placement: 'bottomRight',
      })
      return
    }

    let ret: any = result
    api.success({
      message: 'success',
      duration: null,
      description: (
        <div>
          <a
            target="_blank"
            rel="noreferrer"
            href={
              CONFIG.DASHBOARD_URL +
              ret.value.transactionIntentHash +
              '/details'
            }
          >
            {ret.value.transactionIntentHash}
          </a>
        </div>
      ),
    })
    queryData(address)
  }

  const migratePoolData = async (resourceAddress: string, amount: number) => {
    setButtonStatus(1)
    let data = poolDataArray.find((item) => item.token === resourceAddress)
    // console.log(
    //   data,
    //   poolArray,
    //   poolDataArray,
    //   resourceAddress,
    //   CONFIG.OLD_ENV.MIGRATE_POOL_ADDRESS
    // )
    if (data) {
      let supplyAmount = data.supplyIndex * amount
      let underlying = CONFIG.OLD_ENV.MIGRATE_POOL_ADDRESS.find(
        (item: any) => item.from === resourceAddress
      ).to
      let manifest = `
      CALL_METHOD
        Address("${address}")
        "withdraw"
        Address("${resourceAddress}")
        Decimal("${amount}")
      ;
      TAKE_FROM_WORKTOP
        Address("${resourceAddress}")
        Decimal("${amount}")
        Bucket("bucket1")
      ;
      CALL_METHOD
        Address("${CONFIG.OLD_ENV.LENDING_FACTORY_COMPONENT_ADDRESS}")
        "withdraw"
        Bucket("bucket1")
      ;
      TAKE_FROM_WORKTOP
        Address("${underlying}")
        Decimal("${supplyAmount}")
        Bucket("bucket2")
      ;
      CALL_METHOD
        Address("${CONFIG.LENDING_FACTORY_COMPONENT_ADDRESS}")
        "supply"
        Bucket("bucket2")
      ;
      CALL_METHOD
        Address("${address}")
        "deposit_batch"
        Expression("ENTIRE_WORKTOP")
      ;
      `
      console.log(manifest)

      const result = await rdt?.walletApi.sendTransaction({
        transactionManifest: manifest,
      })

      setButtonStatus(0)

      if (result?.isErr()) {
        api.error({
          message: 'error',
          description: result.error.message,
          // placement: 'bottomRight',
        })
        return
      }

      let ret: any = result
      api.success({
        message: 'success',
        description: (
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href={
                CONFIG.DASHBOARD_URL +
                ret.value.transactionIntentHash +
                '/details'
              }
            >
              {ret.value.transactionIntentHash}
            </a>
          </div>
        ),
      })
      queryData(address)
    }
  }

  return (
    <Layout>
      {contextHolder}
      <LayoutContainer>
        <NavigateButtons />
        <div style={{ marginBottom: '3rem' }} />
        <Typography style={{ margin: '32px 128px 32px 128px' }}>
          <Title>Introduction</Title>

          <Paragraph>
          DeXian Protocol is newly upgraded, original DeXian Lending Protocol users can upgrade their dx$Token to the new version here or go to the old version to manage their CDP.
          </Paragraph>
        </Typography>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            marginLeft: '128px',
          }}
        >
          <div style={{ marginRight: '2rem' }}>account:
          {/* <Button onClick={async ()=>{
            let ret = await queryCurrentEpoch(rdt);
            console.log(ret)
          }}>test</Button> */}
          </div>
          <div style={{ width: '50%' }}>
            <AccountPicker
              disable={walletData.connected ? false : true}
              device={props.device}
              onSelect={changeAccount}
              defaultValue={account}
              items={genAccountList()}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'start',
            marginLeft: '128px',
            marginRight: '128px',
            marginTop: '16px',
          }}
        >
          <Card
            title="Pool Data"
            // extra={<a href="#">More</a>}
            style={{ width: 400, marginRight: '16px' }}
          >
            {status === CDPListStatus.INIT ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Loading fontSize={32} />
              </div>
            ) : (
              <List
                dataSource={poolArray}
                split={true}
                renderItem={(item) => {
                  return (
                    <div
                      style={{
                        marginBottom: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>
                        {item.symbol} ({item.balance})
                      </div>
                      <div>
                        {Number(formatNumber(item.amount)) === 0 ? (
                          <></>
                        ) : (
                          <Button
                            type="primary"
                            size={'small'}
                            onClick={() => {
                              migratePoolData(
                                item.address,
                                Number(formatNumber(item.amount))
                              )
                            }}
                            disabled={buttonStatus == 1}
                          >
                            migrate
                          </Button>
                        )}
                      </div>
                    </div>
                  )
                }}
              />
            )}
          </Card>

          <Card
            title="CDP Data"
            // extra={<a href="#">More</a>}
            style={{ width: 400 }}
          >
            {status === CDPListStatus.INIT ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Loading fontSize={32} />
              </div>
            ) : (
              <List
                dataSource={cdpArray}
                split={true}
                renderItem={(item) => {
                  return (
                    <div
                      style={{
                        marginBottom: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>
                        {item.nonFungibleId} ({item.collateralTokenSymbol}/
                        {item.borrowTokenSymbol}) {item.borrowAmount}
                      </div>
                      <div>
                        {/* <Button
                          type="primary"
                          size={'small'}
                          disabled={buttonStatus == 1}
                          onClick={()=>{migrateCdp(item)}}
                        >
                          migrate
                        </Button> */}
                        <Button type="primary" size={'small'}>
                          {CONFIG.env === 'stokenet' ? (
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={
                                'https://stokenet-lending.dexian.io/#/manage/' +
                                address +
                                '/' +
                                item.nonFungibleId.replaceAll('#', '')
                              }
                            >
                              manage
                            </a>
                          ) : (
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={
                                'https://lending.dexian.io/#/manage/' +
                                address +
                                '/' +
                                item.nonFungibleId.replaceAll('#', '')
                              }
                            >
                              manage
                            </a>
                          )}
                        </Button>
                      </div>
                    </div>
                  )
                }}
              />
            )}
          </Card>
        </div>
      </LayoutContainer>
    </Layout>
  )
}

// Layout
const Layout = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
  height: 100%;
  display: flex;
`
const LayoutContainer = styled.div`
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex: 1 1 0%;
  position: relative;
`

export default Transfer
