import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import { DataRequestBuilder, RadixDappToolkit } from "@radixdlt/radix-dapp-toolkit";
import { RdtProvider } from "./RdtProvider";
import { Provider } from 'react-redux';
import { store } from './store/configureStore.js'; 
import ApiService from './services/ApiService';
import CONFIG from './env/index'

import './index.css';
import 'antd/dist/reset.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const apiService = new ApiService({interval:true});
apiService.subcribe();
apiService.fetchAssetPrice();

// const dAppId = 'account_tdx_2_129th30gyg5w0fh06swecmtg6ddcqfl77qme7ffvqzrgwc7kyelr5tp'  //
// const dAppId = 'account_rdx12xfv4auqudtejxh48aayzwdjruhush5esdc44e5dezjps5xaj3x8zx' //mainnet
// Instantiate DappToolkit
const rdt = RadixDappToolkit({
  dAppDefinitionAddress: CONFIG.DAPP_ID,
  networkId: CONFIG.NETWORK_ID, // network ID 2 is for the stokenet test network 1 is for mainnet
  applicationName: 'DeXian Protocol',
  applicationVersion: '0.1.0',
})
console.log("dApp Toolkit: ", rdt);

// const rdt = RadixDappToolkit(
//   {
//     // dAppName: "Radix dApp",
//     // dAppDefinitionAddress: "account_tdx_e_12xe76ecs939curakgp6sd9668eeqn5rmx4fv9lw3qazqe0tdzs5p6x",
//     // networkId: RadixNetwork.RCnetV3,
//     dAppDefinitionAddress: "account_tdx_2_129th30gyg5w0fh06swecmtg6ddcqfl77qme7ffvqzrgwc7kyelr5tp",
//     networkId: RadixNetwork.Stokenet,
//     applicationName: "Dexian Lending",
//     applicationVersion: '1.0.0'
//   });
// rdt.buttonApi.setTheme('black');
rdt.walletApi.setRequestData(DataRequestBuilder.accounts());
// store.dispatch({type:WalletActionType.SET_RDT, payload:rdt});

root.render(
    <Provider store={store}>
      <RdtProvider
        value={rdt}
      >
        <App />
      </RdtProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
